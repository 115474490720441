import axios from '@axios'

export default {
  namespaced: true,
  state: {
    departmentId: 1,
    condition: {
      //需要在js中修改的数据需要提前声明
      shielding_supplier: [{"title":"其他商品入库专用供应商","id":4184},{"title":"线下销售退货自动入库专用供应商","id":24621}],
      companyName: undefined,
      supplierName: undefined,
      purchaserName: undefined,
      applierName: undefined,
    },
  },
  getters: {
    getDepartmentId(state){
      return state.departmentId
    },
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseorder/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    select(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseorder/select', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseorder/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    toPDF(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorder/pdf', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeView(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseorder/changeView', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/purchaseorder/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorder/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    del(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorder/del', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    orderOver(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/bbmall/purchase/v1/bbm_check_purchase_order', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseorder/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    log(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorder/log', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorder/changeStatus', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    commit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorder/commit', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPrepaidAmount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseorder/getPrepaidAmount', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    saveAgain(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseorder/saveAgain', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changePayPercent(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseorder/changePayPercent', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeCreator(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseorder/changeCreator', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportToExcel(ctx, params) {
     return new Promise((resolve, reject) => {
       axios({
             url: '/api/exportexcelfile/exportToExcel',
             method:'get',
             params: params,
             responseType: 'blob',
           }
       )
          .then(response => resolve(response))
          .catch(error => reject(error))
     })
    },
    getSalesReport(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/exportexcelfile/getSalesReport', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getWHStock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseorder/getWHStock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createStockUp(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseorder/createStockUp', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchBrandData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorder/searchBrandData', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveRemark(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorder/saveRemark', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
