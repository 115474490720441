import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/warehouse/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    submit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/warehouse/submit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchAll(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/warehouse/searchAll', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchtype2(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/warehouse/searchtype2', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/warehouse/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/warehouse/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/warehouse/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehouse/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    list(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehouse/list', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    findWareHouseIdsByStoreIds(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehouse/findWareHouseIdsByStoreIds', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    findWareHouseIds(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehouse/findWareHouseIds', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    contactCompanyQCode(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehouse/contactCompanyQCode', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    findWarehouseByUserId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehouse/findWarehouseByUserId', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    findStoreByWarehouseId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehouse/findStoreByWarehouseId', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getWarehouse(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehouse/getWarehouse', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
