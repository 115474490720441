import axios from '@axios'

export default {
  namespaced: true,
  state: {
    condition:undefined,
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      /*if (condition){
        state.condition = condition
      }*/
      state.condition = condition
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contract/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    select(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contract/select', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contract/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeView(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contract/changeView', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contract/edit', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/contract/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/contract/state', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/contract/changeStatus', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    imminentContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/contract/imminentContract', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
