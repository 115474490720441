<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        :select-mode="selectMode"
        selectable
        @row-selected="onRowSelected"
        @row-dblclicked="openDetails"
      >

        <!-- Columns -->
        <template #cell(user_id)="data">
<!--          <b-form-checkbox-->
<!--              name="user-id"-->
<!--              v-model="checked"-->
<!--              :value="data.item"-->
<!--          >-->
            #{{data.item.user_id}}
<!--          </b-form-checkbox>-->
        </template>
<!--        <template #cell(id)="data">-->
<!--          <b-link-->
<!--              :to="{ name: 'apps-user-edit', query: { id: data.item.id } }"-->
<!--              class="font-weight-bold d-block text-nowrap"-->
<!--          >-->
<!--            #{{ data.item.id }}-->
<!--          </b-link>-->
<!--        </template>-->

        <!-- Column: Type -->
        <template #cell(user_type)="data">
          {{getCodeLabel('user_type', data.item.user_type)}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-user-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-user-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import userUseSelect from './userUseSelect'
import userStore from '../userStore'

export default {
  props: {
    companyId: {
      type: Number,
    },
    departmentId:{
      type:Number,
      default: 0
    },
    isAllState:{
      type:Boolean,
      default: false
    },
    userDepartment:{
      type:String,
      default:""
    },
    selectMode:{
      type:String,
      default:'single'
    },

  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
  },
  created() {
    this.companyIdRelational = this.companyId
  },
  data() {
    return {
      selected: [],
    }
  },
  methods: {

    getSelected: function() {
      return this.selected
    },
    onRowSelected(items) {
      this.selected = items
    },
    openDetails(items,index,event){
      this.$emit("table",items)
    }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('user')) store.registerModule('user', userStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('user')) store.unregisterModule('user')
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      companyIdRelational,

      // UI
    } = userUseSelect({
      userDepartment:props.userDepartment,
      departmentId: props.departmentId,
      isAllState: props.isAllState,
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      companyIdRelational,


      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
