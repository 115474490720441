<template>

  <div class="attachment-wrapper" :class="theme">
    <div class="preview" v-if="theme=='image' || theme=='file'">
      <b-img v-for="(attachment, index) in attachment_list" :key="index"
             :src="attachment.preview_url"
             alt="image"
      />
    </div>
    <b-list-group class="mb-1 files" size="sm" v-if="theme=='files' || theme=='preview'">
      <b-list-group-item v-for="(attachment, index) in attachment_list" :key="index">
        <b-img
            :src="attachment.preview_url"
            alt="image"
        />
        <span><a class="link" :href="attachment.file_url" target="_blank">{{ attachment.file_name }}</a> <feather-icon
            v-if="!readonly" icon="Trash2Icon" v-on:click="remove(index)"/></span>
      </b-list-group-item>
    </b-list-group>
    <b-dropdown
        variant="link"
        no-caret
        v-if="theme=='preview-dropdown' && attachment_list.length > 0"
    >
      <template #button-content>
        <feather-icon
            icon="LinkIcon"
            size="16"
            class="align-middle text-body"
        />
      </template>
      <b-dropdown-item v-for="(attachment, index) in attachment_list" :key="index" :href="attachment.file_url"
                       target="_blank">
        <b-img
            :src="attachment.preview_url"
            alt="image"
        />
        <span>{{ attachment.file_name }}</span>
      </b-dropdown-item>
    </b-dropdown>
    <b-form-file
        v-if="!readonly"
        :placeholder="placeholder"
        drop-placeholder="拖拉文件到这..."
        data-browse="浏览"
        v-model="files"
        @change="change($event)"
        ref="inputFile"
        :id="attachment_id"
        :multiple="multiple"
        size="sm"
    />
  </div>

</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import attachmentStore from './attachmentStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  get_upload_url,
  get_upload_path,
  get_upload_preview
} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BListGroup,
    BListGroupItem,
    vSelect,
    useToast,
  },
  data() {
    return {
      attachment_list: [],
      files: [],
      multiple: false,
    }
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    theme: {
      default: 'image'
    },
    id: '',
    attachment_id: '',
    object_type: '',
    object_id: '',
    placeholder: {
      type: String,
      default: "",
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('attachment')) store.registerModule('attachment', attachmentStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('attachment')) store.unregisterModule('attachment')
    })

    const load = function () {
      if (this.id != null && this.id != '' && this.id != '0' && this.id != '[]') {
        store.dispatch('attachment/list', {ids: this.id}).then(res => {
          this.attachment_list = res.data.data.ext.list
          this.change_preview()
        })
      }
    }

    const change = function (event) {
      console.log('change', event.target.files)
      if (this.multiple == true) {
        let list = new Array()
        let length = event.target.files.length
        console.log('file size: ' + length)
        for (let i = 0; i < length; i++) {
          list.push(event.target.files[i])
        }
        this.files = list
        console.log('files', this.files)
      } else {
        this.files = event.target.files[0]
      }
      this.upload()
    }

    const change_preview = function () {
      if (this.attachment_list) {
        for (let i = 0; i < this.attachment_list.length; i++) {
          let attachment = this.attachment_list[i]
          attachment.file_url = get_upload_url(attachment)
          attachment.preview_url = get_upload_preview(attachment)
        }
      }
    }

    const upload = function () {
      const params = new FormData()
      params.append('multiple', this.multiple)
      if (this.multiple == true) {
        for (let i = 0; i < this.files.length; i++) {
          params.append('file' + i, this.files[i])
        }
      } else {
        params.append('file', this.files)
      }
      params.append('object_type', this.object_type)
      params.append('object_id', this.object_id)

      store.dispatch('attachment/upload', params).then(res => {
        //console.log('res.data, ' + res.data)
        if (res.data.code == 0) {
          if (this.multiple == true) {
            let list = res.data.data
            for (let i = 0; i < list.length; i++) {
              let attachment = list[i]
              if (attachment && attachment.attachment_id > 0) {
                attachment.file_url = get_upload_url(attachment)
                attachment.preview_url = get_upload_preview(attachment)
                if (this.theme == 'image' || this.theme == 'file') {
                  this.attachment_list = new Array()
                } else if (this.theme == 'files') {
                }
                this.attachment_list.push(attachment)
              }
            }
            this.uploaded()
            toast.success(list.length + '个文件已上传!')
          } else {
            let attachment = res.data.data
            if (attachment && attachment.attachment_id > 0) {
              attachment.file_url = get_upload_url(attachment)
              attachment.preview_url = get_upload_preview(attachment)
              if (this.theme == 'image' || this.theme == 'file') {
                this.attachment_list = new Array()
              } else if (this.theme == 'files') {
              }
              this.attachment_list.push(attachment)
              this.uploaded()
              toast.success('文件已上传!')
            }
          }

        } else {
          toast.error(res.data.error)
        }

      })
    }

    const uploaded = function () {
      this.$forceUpdate()
      let ids = new Array()
      for (let i = 0; i < this.attachment_list.length; i++) {
        ids.push(this.attachment_list[i].attachment_id)
      }
      console.log('uploaded', ids.join(','))

      if (this.theme == 'image' || this.theme == 'file') {
        this.$emit('change', this.attachment_id, this.attachment_list[0], ids.join(','),this.files)
      } else if (this.theme == 'files') {
        this.$emit('change', this.attachment_id, this.attachment_list, ids.join(','),this.files)
      }
    }

    const remove = function (index) {
      console.log(index)
      let list = new Array()
      for (let i = 0; i < this.attachment_list.length; i++) {
        if (i != index) {
          list.push(this.attachment_list[i])
        }
      }
      this.attachment_list = list
      this.uploaded()
      toast.success('文件已删除!')
    }

    return {
      load,
      change,
      change_preview,
      upload,
      uploaded,
      remove,

      get_upload_url,
      get_upload_path,
      get_upload_preview,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    if (this.theme == 'preview' || this.theme == 'preview-dropdown') {
      this.readonly = true
    }
    if (this.theme == 'files') {
      this.multiple = true
    }
    this.load()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
