import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCode} from "@core/utils/filter";

export default function purchaseorderitemUseList(config,emit) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: '名称'},
    { key: 'specification', label: '规格'},
    { key: 'purchase_team', label: '销售权'},
    { key: 'qty', label: '数量'},
    { key: 'product_cost_tax', label: '含税成本'},
    { key: 'sales_price', label: '预计供价'},
    // { key: 'product_cost_subtotal', label: '小计'},
    // { key: 'product_cost', label: '成本'},
    // { key: 'product_cost_tax_subtotal', label: '税后小计'},
    { key: 'expected_gross_profit_rate', label: '预计毛利率/%'},
    { key: 'tax_rate', label: '税率'},
    { key: 'tax_subtotal', label: '税额'},
    // { key: 'extra_cost', label: '附加费'},
    // { key: 'extra_cost_subtotal', label: '附加费小计'},
    { key: 'cost', label: '无税金额'},
    { key: 'subtotal', label: '价税合计'},
    { key: 'sales_period', label: '销售周期(天)'},
    { key: 'is_gift', label: '是否赠品'},
    /*{ key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '添加时间', sortable: true },
    { key: 'modify_time', label: '修改时间', sortable: true },*/
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(1000)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('item_id')
  const isSortDirDesc = ref(true)
  const gross_profit = ref(0.00)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
      gross_profit:gross_profit.value
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()

  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    if (config.readonly!==0){
      for (let i = 0; i < tableColumns.length; i++) {
        if (tableColumns[i].key=="actions"){
          tableColumns.splice(i,1)
        }
      }
    }
    store
      .dispatch('purchaseorderitem/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        order_id : config.order_id,
        readonly:config.readonly,
      })
      .then(response => {
        const data = response.data.data
        gross_profit.value = data.ext.gross_profit
        const list = data.ext.list
        for (let i = 0; i < list.length; i++) {
          list[i].purchaseTeam = getCode('purchase_team',list[i].purchase_team_id)
        }
        emit('table', list)
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '采购单明细列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    gross_profit,
    // Extra Filters
  }
}
