import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "axios";

export default function productUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    // { key: 'company_creator', label: '创建组织', sortable: true },
    //{ key: 'company_owner', label: '使用组织', sortable: true },
    { key: 'code', label: '编码', sortable: true },
    { key: 'name', label: '名称', sortable: true },
    { key: 'specification', label: '规格型号', sortable: true },
    // { key: 'category_id', label: '分组ID', sortable: true },
    // { key: 'brand_id', label: '品牌ID', sortable: true },
    { key: 'product_brandlabel', label: '品牌标签', sortable: true },
    // { key: 'tax_rate', label: '默认税率 tax_rate', sortable: true },
    // { key: 'product_type', label: '存货类别 product_type', sortable: true },
    // { key: 'tax_type', label: '税分类 product_faxtype', sortable: true },
    // { key: 'cost_added_rate', label: '结算成本价加减价比例(%)', sortable: true },
    // { key: 'gross_weight', label: '毛重', sortable: true },
    // { key: 'net_weight', label: '净重', sortable: true },
    // { key: 'weight_unit', label: '重量单位 weight_unit', sortable: true },
    { key: 'box_quantity', label: '单箱标准数量', sortable: true },
    // { key: 'enable_stock_lock', label: '是否可以锁库', sortable: true },
    // { key: 'enable_stock_inventory', label: '是否周期盘点', sortable: true },
    // { key: 'inventory_period', label: '盘点周期  inventory_period', sortable: true },
    // { key: 'inventory_day', label: '盘点固定日期(第几天)', sortable: true },
    // { key: 'guarantee_period_unit', label: '保质期单位 product_unit_period', sortable: true },
    // { key: 'guarantee_period', label: '保质期', sortable: true },
    // { key: 'best_shelf_life', label: '在架寿命期', sortable: true },
    // { key: 'cost_reference', label: '参考成本', sortable: true },
    // { key: 'min_qty', label: '最小库存', sortable: true },
    // { key: 'safe_qty', label: '安全库存', sortable: true },
    // { key: 'max_qty', label: '最大库存', sortable: true },
    // { key: 'repeat_qty', label: '再订货点', sortable: true },
    //{ key: 'status', label: '数据状态', sortable: true },
    //{ key: 'state', label: '禁用状态', sortable: true },
    //{ key: 'product_attribute', label: '物料属性', sortable: true },
    { key: 'product_unit', label: '基本单位', sortable: true },
    // { key: 'attachments', label: '', sortable: true },
    // { key: 'create_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人ID', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
    { key: 'stockStr', label: '库存' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('product_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    if (config.storeId===0&&config.companyId===0){
      tableColumns.splice(6,5)
    }
    store
      .dispatch('product/select', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        storeId:config.storeId,
        companyId:config.companyId,
        warehouseId:config.warehouseId,
        purchaseTeamUserId:config.purchaseTeamUserId,
        purchaseTeamStoreId:config.purchaseTeamStoreId,
      })
      .then(response => {
        const data = response.data.data
        let list = data.ext.list
        listTotals.value = data.ext.totals
        for (let i = 0; i < list.length; i++) {
          list[i]['stockStr'] = "查看"
        }
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '商品列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }



  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
