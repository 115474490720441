import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function contractUseOneList(config) {
  // Use toast
  const toast = useToast()
  console.log("config",config)
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'contractTitle', label: '标题' },
    { key: 'applyUserName', label: '申请人姓名' },
    { key: 'ourCorporationName', label: '我方单位名称' },
    { key: 'theirCorporationName', label: '对方单位名称' },
    { key: 'contractNo', label: '合同编号' },
    { key: 'contractStatus', label: '审核状态' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('contract_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    console.log(config)
    //vmi
    if(config.type_id&&config.type_id == '3'){
      return
    }
    if (config.type==2){
      if (config.other_company_id==0){
        config.other_company_id=null
      }
      if (config.zt_company_id==0){
        config.zt_company_id=null
      }

    }
    store
      .dispatch('contractone/select', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        // order_by: orderBy.value,
        // order_desc: isSortDirDesc.value === true ? 'desc':'',
        // type: config.type,
        theirCorporationId:config.other_company_id,
        ourCorporationCode:config.zt_company_id,
        // status:config.status
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        if(list&&list.length>0){
          for(let i=0;i<list.length;i++){
            if(list[i].contract_status == 'N'){
              list[i].contract_status = '新建'
            }else if(list[i].contract_status == 'O'){
              list[i].contract_status = '已作废'
            }else if(list[i].contract_status == 'RUN'){
              list[i].contract_status = '协同中'
            }else if(list[i].contract_status == 'P'){
              list[i].contract_status = '审核中'
            }else if(list[i].contract_status == 'A'){
              list[i].contract_status = '已批准'
            }else if(list[i].contract_status == 'R'){
              list[i].contract_status = '已拒绝'
            }else if(list[i].contract_status == 'S'){
              list[i].contract_status = '已签订'
            }else if(list[i].contract_status == 'C'){
              list[i].contract_status = '已取消'
            }else if(list[i].contract_status == 'D'){
              list[i].contract_status = '已暂停'
            }else if(list[i].contract_status == 'H'){
              list[i].contract_status = '用章驳回'
            }else if(list[i].contract_status == 'F'){
              list[i].contract_status = '已终止'
            }else if(list[i].contract_status == 'E'){
              list[i].contract_status = '已结束'
            }else if(list[i].contract_status == 'B'){
              list[i].contract_status = '待申请签章'
            }else if(list[i].contract_status == 'G'){
              list[i].contract_status = '用章批准'
            }else if(list[i].contract_status == 'SIGNING'){
              list[i].contract_status = '签署中'
            }else if(list[i].contract_status == 'AR'){
              list[i].contract_status = '已归档'
            }else if(list[i].contract_status == 'DI'){
              list[i].contract_status = '已解除'
            }else if(list[i].contract_status == 'OTHER'){
              list[i].contract_status = '其他'
            }else if(list[i].contract_status == 'WAIT_SUBMIT'){
              list[i].contract_status = '待提交'
            }else if(list[i].contract_status == 'CLOSED'){
              list[i].contract_status = '已关闭'
            }
          }
        }
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '采购框架协议列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,

    // Extra Filters
  }
}
