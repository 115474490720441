<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          select-mode="single"
          selectable
          @row-selected="onRowSelected"
          @row-dblclicked="openDetails"
      >

        <!-- Column -->
        <template #cell(contractTitle)="data">
          {{data.item.contract_title}}
        </template>
        <template #cell(applyUserName)="data">
          {{data.item.apply_user_name}}
        </template>
        <template #cell(ourCorporationName)="data">
          {{data.item.our_corporation_name}}
        </template>
        <template #cell(theirCorporationName)="data">
          {{data.item.their_corporation_name}}
        </template>
        <template #cell(contractNo)="data">
          {{data.item.contract_serial_number}}
        </template>
        <template #cell(contractStatus)="data">
          {{data.item.contract_status}}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import { ref, onUnmounted } from '@vue/composition-api'
import {
  avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions
} from '@core/utils/filter'
import contractUseOneList from './ContractUseOneSelect'
import contractOneStore from './contractOneStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      selected: [],

    }
  },
  props:{
    type :{
      type : Number,
      default: 1
    },
    status :{
      type : Number,
      default : 1
    },
    other_company_id:{
      type : String,
      default : null
    },
    zt_company_id:{
      type : String,
      default : null
    },
    type_id:{
      type : String,
      default : null
    },
  },
  methods: {
    getSelected: function() {
      return this.selected
    },
    onRowSelected(items) {
      this.selected = items
    },
    openDetails(items,index,event){
      this.$emit("table",items)
    }
  },
  setup(props) {

    // Register module
    if (!store.hasModule('contractone')) store.registerModule('contractone', contractOneStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('contractone')) store.unregisterModule('contractone')
    })

    const jump = function (res) {
      if (res.item.agreement_type===1){
        return "apps-contract-edit"
      }
      if (res.item.agreement_type===4){
        return "apps-contract-edit-xs"
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      resolveContractTypeCode,
      resolveStateCode,
    } = contractUseOneList({
      type: props.type,
      other_company_id:props.other_company_id,
      status:props.status,
      zt_company_id:props.zt_company_id,
      type_id:props.type_id
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      jump,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      // UI
      resolveContractTypeCode,
      resolveStateCode,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
