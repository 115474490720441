import * as dayjs from 'dayjs'
/**
 * 仅针对时间戳秒数转换
 * */
const getDate = (value,init) => init ? dayjs(value || undefined) : value ? dayjs(value) : value

export const toTime = (value,init) => {
  return format(value,'YYYY-MM-DD HH:mm',init)
}
export const toDate = (value,init) => {
  return format(value,'YYYY-MM-DD',init)
}
export const toMonth = (value,init) => {
  return format(value,'YYYY-MM',init)
}

const format = (value,formatter,init) =>{
  const val = value ? value * 1000 : undefined
  // const format = getDate(val).format(formatter)
  const date = getDate(val,init)
  return date ? date.format(formatter) : value
}

export const second = (formatter,init) => {
  const date = getDate(formatter,init)
  return date ? date.unix() : formatter;
}

export const addDate = (date,value,unit) => {
  return dayjs(date).add(value,unit).format('YYYY-MM-DD')
}
