import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    caltotal(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorderitem/caltotal', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseorderitem/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    itemList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseorderitem/itemList', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseorderitem/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeView(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseorderitem/changeView', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/purchaseorderitem/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addnewpro(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseorderitem/addnewpro', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorderitem/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseorderitem/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    del(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorderitem/delorderitem', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    calExtraCost(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorderitem/calextracost', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrderItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseorderitem/updateOrderItem', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseorderitem/exportExcel', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    excelModelDownload(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseorderitem/excelModelDownload', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
