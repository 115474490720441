<template>
  <b-card
      class="purchaseorder-edit-wrapper"
  >
    <!--    <ValidationProvider rules="required" name="类型" #default="{ errors }">-->

    <!--      <small class="text-danger">{{ errors[0] }}</small>-->
    <!--    </ValidationProvider>-->
    <ValidationObserver ref="observer">
      <b-form id="purchaseorderForm" class="edit-form mt-2">
        <b-row>
          <b-col md="12">
            <div class="inner-card-title">基本信息</div>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="申请人"
                label-for="applier_name"
                label-size="sm"
                :class="'mb-1 '+ required"
            >
              <ValidationProvider :rules="required" name="申请人" #default="{ errors }">
                <b-form-input
                    id="applier_name"
                    size="sm"
                    v-model="purchaseorder.applier_name"
                    @click="showSingleModal('申请人')"
                    placeholder="点击搜索姓名"
                    readonly
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="申请部门"
                label-for="applier_department_name"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="applier_department_name"
                  size="sm"
                  v-model="purchaseorder.applier_department_name"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="所属组织"
                label-for="applier_company_name"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="applier_company_name"
                  size="sm"
                  v-model="purchaseorder.applier_company_name"
                  readonly
              />
            </b-form-group>
          </b-col>






          <b-col md="12">
            <div class="inner-card-title">订单信息</div>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="业务类型"
                label-for="type_id"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="类型" #default="{ errors }">
                <v-select
                    id="type_id"
                    :options="getCodeOptions('business_type')"
                    :clearable="false"
                    v-model="type_id"
                    @input="changeSelect('type_id',$event)"
                    class="select-size-sm "
                    :disabled="disableTypeIdFlag"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="是否内部交易"
                label-for="trade_type"
                label-size="sm"
                class="mb-1"
            >
              <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  id="is_insidetrade"
                  v-model="purchaseorder.is_insidetrade"
                  value="1"
                  unchecked-value="0"
                  readonly=""
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="采购订单编号"
                label-for="order_no"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="order_no"
                  size="sm"
                  v-model="purchaseorder.order_no"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="ERP采购订单号"
                label-for="order_no_erp"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="order_no_erp"
                  size="sm"
                  v-model="purchaseorder.order_no_erp"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="原产地"
                label-for="source_place"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="source_place"
                  size="sm"
                  v-model="purchaseorder.source_place"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="签约主体名称"
                label-for="company_name"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="签约主体名称" #default="{ errors }">
                <b-form-input
                    id="company_name"
                    size="sm"
                    v-model="purchaseorder.company_name"
                    @click="showSingleModal('集团公司')"
                    readonly
                    placeholder="点击搜索集团公司"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="采购员名称"
                label-for="purchaser_name"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="采购员" #default="{ errors }">
                <b-form-input
                    id="purchaser_name"
                    size="sm"
                    v-model="purchaseorder.purchaser_name"
                    @click="showSingleModal('采购员')"
                    placeholder="点击搜索采购员"
                    :state="errors.length > 0 ? false:null"
                    readonly
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系电话"
                label-for="purchaser_mobile"
                label-size="sm"
                :class="'mb-1 '+ required"

            >
              <ValidationProvider :rules="required" name="联系电话" #default="{ errors }">
                <b-form-input
                    id="purchaser_mobile"
                    size="sm"
                    readonly
                    v-model="purchaseorder.purchaser_mobile"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="供应商"
                    label-for="supplier_name"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="供应商" #default="{ errors }">
                <b-form-input
                        id="supplier_name"
                        size="sm"
                        v-model="purchaseorder.supplier_name"
                        @click="showSingleModal('供应商')"
                        readonly
                        placeholder="点击搜索供应商"
                        :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="供应商联系人"
                label-for="supplier_contact_name"
                label-size="sm"
                :class="'mb-1 '+ required"
            >
              <ValidationProvider :rules="required" name="联系人" #default="{ errors }">
                <b-form-input
                    id="supplier_contact_name"
                    size="sm"
                    v-model="purchaseorder.supplier_contact_name"
                    @click="showSingleModal('供应商联系人')"
                    readonly
                    placeholder="点击搜索供应商联系人"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系电话"
                label-for="supplier_contact_mobile"
                label-size="sm"
                :class="'mb-1 '+ required"
            >
              <ValidationProvider :rules="required" name="供应商联系电话" #default="{ errors }">
                <b-form-input
                    id="supplier_contact_mobile"
                    size="sm"
                    readonly
                    v-model="purchaseorder.supplier_contact_mobile"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!-- yang  redmine 661 收款账户  -->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款账户"
                    label-for="supplier_bank_id"
                    label-size="sm"
                    class="mb-1"
            >

              <b-form-input
                      id="supplier_bank_id"
                      size="sm"
                      v-model="accountNo"
                      @click="showSingleModal('收款账户')"
                      readonly
                      placeholder="点击搜索收款账户"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="供应商订单号"
                label-for="supplier_order_no"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="supplier_order_no"
                  size="sm"
                  v-model="purchaseorder.supplier_order_no"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="采购单用途"
                label-for="use_type"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="采购单用途" #default="{ errors }">
                <v-select
                    id="use_type"
                    :options="getCodeOptions('purchase_use_type')"
                    :clearable="true"
                    v-model="use_type"
                    :reduce="option => option.value"
                    class="select-size-sm "
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="平台品牌方"
                label-for="brand"
                label-size="sm"
            >
              <v-select
                  id="brand"
                  label="title"
                  :clearable="true"
                  @input="changeBrand($event)"
                  :options="brandArr"
                  v-model="brand"
                  class="select-size-sm"
              />
            </b-form-group>
          </b-col>

           <!--源头供应商 -->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="源头供应商"
                    label-for="supplier_name_source"
                    label-size="sm"
                    class="mb-1"
            >
                <b-form-input
                        id="supplier_name_source"
                        size="sm"
                        v-model="purchaseorder.supplier_name_source"
                        @click="showSingleModal('源头供应商')"
                        readonly
                        placeholder="点击搜索源头供应商"
                />

            </b-form-group>
          </b-col>

          <!--备采类型-->
<!--          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="备采类型"
                label-for="stockup_type"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="备采类型" #default="{ errors }">
                <v-select
                    id="stockup_type"
                    :options="stockupTypeOptions"
                    :clearable="true"
                    v-model="purchaseorder.stockup_type"
                    :reduce="option => option.value"
                    class="select-size-sm "
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>-->

          <!--资金池-->


            <b-col md="12" >
              <div class="inner-card-title">资金池信息</div>
            </b-col>
            <b-col md="4" >
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="是否预充"
                      label-for="trade_type"
                      label-size="sm"
                      class="mb-1"
              >
                <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        id="br_pre_order"
                        v-model="br_pre_order"
                        value="1"
                        unchecked-value="0"
                        readonly=""
                ></b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="4" >
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="是否使用资金池"
                      label-for="trade_type"
                      label-size="sm"
                      class="mb-1"
              >
                <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        id="br_isuse_reward"
                        v-model="br_isuse_reward"
                        value="1"
                        unchecked-value="0"
                        :disabled="disabledBool"
                ></b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="4" v-show="requiredForShow">

              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="选择资金池"
                      label-for="reward_name"
                      label-size="sm"
                      :class="'mb-1 '+ requiredFor"
              >
                <ValidationProvider :rules="requiredFor" name="资金池" #default="{ errors }">
                  <b-form-input
                          id="br_reward_id"
                          size="sm"
                          v-model="rewardName"
                          @click="showSingleModal('资金池')"
                          readonly
                          placeholder="点击选择资金池"
                          :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>



          <b-col md="12">
            <div class="inner-card-title">合同信息</div>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="合同编号"
                label-for="contract_no"
                label-size="sm"
                :class="'mb-1 '+ required"
            >
              <ValidationProvider :rules="required" name="供应商合同编号" #default="{ errors }">
                <b-form-input
                    id="contract_no"
                    size="sm"
                    v-model="purchaseorder.contract_no"
                    @click="showSingleModal('供应商合同')"
                    readonly
                    placeholder="点击搜索供应商合同编号"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="合同类型"
                label-for="contract_type"
                label-size="sm"
                :class="'mb-1 '+ required"
            >
              <b-form-input
                  id="contract_type"
                  size="sm"
                  readonly
                  v-model="contractType"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="oneSwitch != '1'">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="发票类型"
                label-for="invoice_type"
                label-size="sm"
                :class="'mb-1 '+ required"
            >

              <ValidationProvider :rules="required" name="发票类型" #default="{ errors }">
                <v-select
                    id="invoice_type"
                    :options="getCodeOptions('invoice_type')"
                    :clearable="true"
                    v-model="invoice_type"
                    @input="changeSelect('invoice_type',$event)"
                    class="select-size-sm"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-form-group>
          </b-col>
          <b-col md="4" v-else-if="oneSwitch == '1'">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="发票类型"
                label-size="sm"
                :class="'mb-1 '+ required"
            >
              <b-form-input
                  size="sm"
                  readonly
                  v-model="invoice_type"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="oneSwitch != '1'">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="付款方式"
                label-for="payment_method"
                label-size="sm"
                :class="'mb-1 '+ required"
            >
              <ValidationProvider :rules="required" name="付款方式" #default="{ errors }">
                <v-select
                    id="payment_method"
                    :options="getCodeOptions('agreement_pay_method')"
                    :clearable="true"
                    v-model="payment_method"
                    @input="changeSelect('payment_method',$event)"
                    class="select-size-sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-else-if="oneSwitch == '1'">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="付款方式"
                label-size="sm"
                :class="'mb-1 '+ required"
            >
              <b-form-input
                  size="sm"
                  readonly
                  v-model="payment_method"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="结算方式"
                label-for="settlement_method"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="settlement_method"
                  size="sm"
                  v-model="purchaseorder.settlement_method"
                  readonly
              />
            </b-form-group>
          </b-col>
           <!--提货方式-->
          <b-col md="4" v-if="oneSwitch != '1'">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="提货方式"
                    label-for="pick_method"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider :rules="'required'" name="提货方式" #default="{ errors }">
                <v-select
                        id="pick_method"
                        :options="getCodeOptions('pickup_method_CG')"
                        :clearable="true"
                        v-model="pick_method"
                        @input="changeSelect('pick_method',$event)"
                        class="select-size-sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-else-if="oneSwitch == '1'">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="提货方式"
                    label-size="sm"
                    :class="'mb-1 '+ required"
            >
              <b-form-input
                  size="sm"
                  readonly
                  v-model="pick_method"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <div class="inner-card-title">物流信息</div>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="运输方式"
                label-for="delivery_type"
                label-size="sm"
                class="mb-1"
            >
              <v-select
                  id="delivery_type"
                  :options="getCodeOptions('delivery_method_purchase')"
                  :clearable="true"
                  v-model="delivery_type"
                  @input="changeSelect('delivery_type',$event)"
                  class="select-size-sm"
              >
              </v-select>

            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="货物起运地"
                label-for="start_place"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="start_place"
                  size="sm"
                  v-model="purchaseorder.start_place"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="交货仓库"
                label-for="warehouse_name"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="warehouse_name"
                  size="sm"
                  v-model="purchaseorder.warehouse_name"
                  @click="showFromWHModal()"
                  placeholder="请选择仓库"
                  readonly
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="详细地址"
                label-for="end_place"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="end_place"
                  size="sm"
                  v-model="purchaseorder.end_place"
              />
            </b-form-group>
          </b-col>

          <!--省份-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="省份"
                label-for="province_id"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="province_id"
                  :options="province_options"
                  :clearable="false"
                  v-model="province_id"
                  @input="onChangeProvince($event)"
                  class="select-size-sm"
                  placeholder="请选择省份"
                  :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <!--城市-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="城市"
                label-for="city_id"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="city_id"
                  :options="city_options"
                  :clearable="false"
                  v-model="city_id"
                  @input="onChangeCity($event)"
                  class="select-size-sm"
                  placeholder="请选择城市"
                  :reduce="option => option.value"
                  :disabled="disabledcity"
              />
            </b-form-group>
          </b-col>
          <!--地区-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="城市"
                label-for="area_id"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="area_id"
                  :options="area_options"
                  :clearable="false"
                  v-model="area_id"
                  class="select-size-sm"
                  placeholder="请选择地区"
                  :reduce="option => option.value"
                  :disabled="disabledarea"
              />
            </b-form-group>
          </b-col>
<!--          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="省市区"
                label-for="warehouse_area"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="warehouse_area"
                  size="sm"
                  v-model="purchaseorder.warehouse_area"
              />
            </b-form-group>
          </b-col>-->

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="收货人"
                label-for="warehouse_contact"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="warehouse_contact"
                  size="sm"
                  v-model="purchaseorder.warehouse_contact"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="收货电话"
                label-for="warehouse_mobile"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="warehouse_mobile"
                  size="sm"
                  v-model="purchaseorder.warehouse_mobile"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预计发货时间"
                label-for="delivery_time"
                label-size="sm"
                class="mb-1"
            >
              <flat-pickr
                  v-model="purchaseorder.delivery_time"
                  class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="要求到货日期"
                label-for="arrival_time"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider :rules="'required'" name="要求到货日期" #default="{ errors }">
                <flat-pickr
                    v-model="purchaseorder.arrival_time"
                    class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

<!--          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="收货人信息"
                label-for="delivery_info"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="delivery_info"
                  size="sm"
                  v-model="purchaseorder.delivery_info"
              />
            </b-form-group>
          </b-col>-->

          <b-col md="12">
            <div class="inner-card-title">其他信息</div>
          </b-col>
          <b-col md="12">
            <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="协作人员"
                    label-for="together"
                    label-size="sm"
                    class="mb-1"
            >
                <v-select
                        id="together"
                        :options="getCodeOptions('purchaseUser')"
                        :clearable="true"
                        v-model="purchaseUserSelected"
                        multiple
                        @input="changeSelectPurchaser('together',$event)"
                        class="select-size-sm">
                  <template #option="{label,icon}">
                    <feather-icon
                            icon="UserIcon"
                            size="16"
                            class="align-middle mr-25"
                    />
                    <span> {{ label }}</span>
                  </template>

                </v-select>
              <!-- -->
            </b-form-group>
          </b-col>

          <b-col md="12" v-if="[2,4,5,6,8].includes(purchaseorder.status) && purchaseorder.type_id !== 3">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="变更理由及影响"
                label-for="memo"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="memo"
                  size="sm"
                  v-model="purchaseorder.ext.oa_reason"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="备注"
                label-for="remark"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="remark"
                  v-model="purchaseorder.remark"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="采购专用备注"
                label-for="remark_purchase"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="remark"
                  v-model="purchaseorder.remark_purchase"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="订单(双章)扫描件上传"
                label-for="attachments_order"
                label-size="sm"
                :class="'mb-1'"
            >
              <attachment-upload v-if="purchaseorder.loaded" :theme="'files'"
                                 :attachment_id="'attachments_order'"
                                 :id="purchaseorder.attachments_order"
                                 :object_type="'purchase_order'"
                                 :object_id="purchaseorder.order_id"
                                 @change="onUploaded"
              />

            </b-form-group>
          </b-col>
          <b-col md="12" v-show="false">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="相关附件"
                label-for="attachments_name"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="attachments_name"
                  size="sm"
                  v-model="purchaseorder.attachments_name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="相关附件"
                label-for="attachments_ids"
                label-size="sm"
                class="mb-1"
            >

              <attachment-upload v-if="purchaseorder.loaded" :theme="'files'"
                                 :attachment_id="'attachments_ids'"
                                 :id="purchaseorder.attachments_ids"
                                 :object_type="'purchase_order'"
                                 :object_id="purchaseorder.order_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>


          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="毛利测算表附件"
                label-for="attachments_grossprofit"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload v-if="purchaseorder.loaded" :theme="'files'"
                                 :attachment_id="'attachments_grossprofit'"
                                 :id="purchaseorder.attachments_grossprofit"
                                 :object_type="'purchase_order'"
                                 :object_id="purchaseorder.order_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <div class="inner-card-title">明细列表</div>
          </b-col>
          <b-col>
            <purchase-order-item-list ref="itemList" :isExitInbound="isExitInbound" :purchase-order="purchaseorder" :rewardId="br_reward_id_para" v-if="itemListShowFlag"
                                      :readonly="readonly" v-on:table="fromChildrenOnNum" @disableTypeId="disableTypeId">

            </purchase-order-item-list>
          </b-col>


          <b-col md="12">
            <div class="inner-card-title">财务信息</div>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="是否预付款"
                label-for="need_advance"
                label-size="sm"
                class="mb-1"
            >

              <b-form-checkbox
                  name="check-button"
                  switch
                  value="1"
                  unchecked-value="0"
                  id="need_advance"
                  v-model="purchaseorder.need_advance"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="结算次数"
                label-for="settlement_times"
                label-size="sm"
                :class="'mb-1 '+required"
            >
              <!-- 到底什么原因导致这个不显示不知道  这个v-if不能去掉 -->
              <ValidationProvider v-if="true"  :rules="required" name="结算次数" #default="{ errors }">
                <v-select
                    id="settlement_times"
                    :options="settlementTimesArray"
                    :clearable="true"
                    v-model="settlement_times"
                    @input="changeSelect('settlement_times',$event)"
                    class="select-size-sm"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预计采购费用"
                label-for="purchase_total"
                label-size="sm"
                :class="'mb-1 '+ required"
            >
              <ValidationProvider  :rules="required" name="预计采购费用" #default="{ errors }">
                <b-form-input
                    id="purchase_total"
                    size="sm"
                    v-model="purchaseorder.purchase_total"
                    type="number"
                    readonly
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-form-group>
          </b-col>
          <!--          首款-->
          <b-col md="12"
                 v-if="purchaseorder.settlement_times==3||purchaseorder.settlement_times==1||purchaseorder.settlement_times==2">
            <b-row>
              <b-col md="4">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                        label-cols="5"
                        label-cols-lg="5"
                        label="首款比例/%"
                        label-for="first_pay_percent"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="first_pay_percent"
                          size="sm"
                          v-model="purchaseorder.first_pay_percent"
                          type="number"
                          @change="changePayPercent($event,'first_pay_amount','first_pay_percent')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                        label-cols="5"
                        label-cols-lg="5"
                        label="首款金额"
                        label-for="first_pay_amount"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="first_pay_amount"
                          size="sm"
                          v-model="purchaseorder.first_pay_amount"
                          readonly
                          type="number"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="首款付款条件"
                    label-for="first_pay_condition"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <ValidationProvider :rules="required" name="首款付款条件" #default="{ errors }">
                    <b-form-input
                        id="first_pay_condition"
                        size="sm"
                        v-model="purchaseorder.first_pay_condition"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="首款预计付款时间"
                    label-for="first_pay_paydate"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <ValidationProvider :rules="required" name="首款预计付款时间" #default="{ errors }">
                    <flat-pickr
                        v-model="purchaseorder.first_pay_paydate"
                        class="form-control"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!--          中款-->
          <b-col md="12" v-if="purchaseorder.settlement_times==3">
            <b-row>
              <b-col md="4">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                        label-cols="5"
                        label-cols-lg="5"
                        label="中期款比例/%"
                        label-for="middle_pay_percent"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="middle_pay_percent"
                          size="sm"
                          v-model="purchaseorder.middle_pay_percent"
                          @change="changePayPercent($event,'middle_pay_amount','middle_pay_percent')"
                          type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                        label-cols="5"
                        label-cols-lg="5"
                        label="中期款金额"
                        label-for="middle_pay_amount"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="middle_pay_amount"
                          size="sm"
                          v-model="purchaseorder.middle_pay_amount"
                          type="number"
                          readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="中期款付款条件"
                    label-for="middle_pay_condition"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <ValidationProvider :rules="required" name="中期款付款条件" #default="{ errors }">
                    <b-form-input
                        id="middle_pay_condition"
                        size="sm"
                        v-model="purchaseorder.middle_pay_condition"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="中期款预计付款时间"
                    label-for="middle_pay_paydate"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <ValidationProvider :rules="required" name="中期款预计付款时间" #default="{ errors }">
                    <flat-pickr
                        v-model="purchaseorder.middle_pay_paydate"
                        class="form-control"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <!--          尾款-->
          <b-col md="12" v-if="purchaseorder.settlement_times==3||purchaseorder.settlement_times==2">
            <b-row>
              <b-col md="4">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                        label-cols="5"
                        label-cols-lg="5"
                        label="尾款比例/%"
                        label-for="last_pay_percent"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="last_pay_percent"
                          size="sm"
                          v-model="purchaseorder.last_pay_percent"
                          @change="changePayPercent($event,'last_pay_amount','last_pay_percent')"
                          type="number"
                          readonly=""
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                        label-cols="5"
                        label-cols-lg="5"
                        label="尾款金额"
                        label-for="last_pay_amount"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="last_pay_amount"
                          size="sm"
                          v-model="purchaseorder.last_pay_amount"
                          type="number"
                          readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="尾款付款条件"
                    label-for="last_pay_condition"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <ValidationProvider :rules="required" name="尾款付款条件" #default="{ errors }">
                    <b-form-input
                        id="last_pay_condition"
                        size="sm"
                        v-model="purchaseorder.last_pay_condition"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="尾款预计付款时间"
                    label-for="last_pay_paydate"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <ValidationProvider :rules="required" name="尾款预计付款时间" #default="{ errors }">
                    <flat-pickr
                        v-model="purchaseorder.last_pay_paydate"
                        class="form-control"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                </b-form-group>
              </b-col>
            </b-row>
          </b-col>


        </b-row>

      </b-form>
      <!-- form -->

    </ValidationObserver>
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <company-select
          ref="companySelect" :companyType="companyType" v-if="modalName == '集团公司' || modalName == '供应商' || modalName == '源头供应商' "
          v-on:table="fromChildren"
      >
      </company-select>

      <user-select
          ref="purchaserSelect" :department-id="departmentId" v-else-if="modalName == '采购员'"
          v-on:table="fromChildren"
      >
      </user-select>

      <contact-select
          ref="contactSelect" :company-id="purchaseorder.supplier_id" v-else-if="modalName == '供应商联系人'"
          v-on:table="fromChildren"
      >
      </contact-select>

      <reward-select
              ref="brandRewardSelect" :supplierId="purchaseorder.supplier_id" v-else-if="modalName ==='资金池'"
              v-on:table="fromChildren"
      >
      </reward-select>

      <contract-select
          ref="contractSelect" :type="1" :status="2" :our_company_id="purchaseorder.company_id"
          :other_company_id="purchaseorder.supplier_id" v-else-if="modalName == '供应商合同'&&oneSwitch != '1'" v-on:table="fromChildren"
      >
      </contract-select>

      <contract-one-select
          ref="contractSelect" :type="1" :status="2" :zt_company_id="purchaseorder.zt_company_id" :type_id="purchaseorder.type_id"
          :other_company_id="purchaseorder.one_supplier_id" v-else-if="modalName == '供应商合同'&&oneSwitch == '1'" v-on:table="fromChildren"
      >
      </contract-one-select>

      <user-select
          ref="applierSelect" :department-id="departmentId" v-else-if="modalName == '申请人' " v-on:table="fromChildren"
      >
      </user-select>

      <!--yang-->
      <bank-account-list
              ref="ourBankSelects" :companyId="purchaseorder.supplier_id"  v-else-if="modalName == '收款账户'  "v-on:table="fromChildren"
      >
      </bank-account-list>

    </b-modal>

    <b-modal
        id="modal-warehouse"
        ok-only
        ok-title="确认"
        @ok="onSelectWarehouse"
        centered
        size="lg"
        :title="'请选择仓库'"
        ref="fromWHModal"
    >
      <ware-house-select
          ref="whSelect" v-on:table="fromChildren($event,'from')"
      >
      </ware-house-select>
    </b-modal>

    <b-col
        cols="12"
        class="mt-50"
    >
      <b-button
          variant="primary"
          class="mr-1"
          @click="save"
          v-if="readonly === 0"
      >
        保存
      </b-button>
      <b-button
          variant="outline-secondary"
          @click="cancel"
      >
        返回
      </b-button>
    </b-col>


  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { computed, onUnmounted, reactive, ref, toRefs } from '@vue/composition-api'
import store from '@/store'
import purchaseorderStore from './purchaseorderStore'
import bankaccountStore from '@/views/apps/bankaccount/bankaccountStore'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
// Notification
import {useToast} from 'vue-toastification/composition'
import {getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty , isNumber} from '@core/utils/filter'
import { second ,toDate} from '@core/utils/dayjs/utils'
import purchaseorderitemStore from '../purchaseorderitem/purchaseorderitemStore'
import CompanySelect from '@/views/apps/company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import ContactSelect from '@/views/apps/contact/contact-modal/ContactSelect'
import ContractSelect from '@/views/apps/contract/ContractSelect'
import ContractOneSelect from '@/views/apps/contract/ContractOneSelect'
import PurchaseOrderItemList from '@/views/apps/purchaseorderitem/PurchaseOrderItemList'
import flatPickr from 'vue-flatpickr-component'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import contactStore from '@/views/apps/contact/contactStore'
import axios from "@/libs/axios";
import purchaseorder from "@/router/routes/apps/purchaseorder";
import contractStore from "@/views/apps/contract/contractStore";
import BankAccountList from '@/views/apps/bankaccount/bankaccount-modal/BankAccountList'
import userStore from "@/views/apps/user/userStore";
import ModalSelect from "@/views/components/modal/ModalSelect";
import brandrewardStore from "@/views/apps/brandreward/brandrewardStore";
import RewardSelect from "@/views/apps/brandreward/purchaseorder/BrandRewardSelect";
import brand from "@/router/routes/apps/brand";
import AreaSelect from "@/views/apps/purchaseorder/AreaSelect";
import areaStore from "@/views/apps/area/areaStore";
export default {
  components: {
    PurchaseOrderItemList,
    AreaSelect,
    WareHouseSelect,
    ContractSelect,
    ContractOneSelect,
    UserSelect,
    CompanySelect,
    ContactSelect,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BModal,
    BFormCheckbox,
    vSelect,
    useToast,
    flatPickr,
    AttachmentUpload,
    BankAccountList,
    ModalSelect,
    RewardSelect
  },
  data() {
    return {
      id: ref(0),
      oneSwitch: null,
      disableTypeIdFlag: false,
      purchaseorder: ref({}),
      groupList: [],
      brandAllArr: [],
      brandArr: [],
      brand: null,
      province_id: null,
      city_id: null,
      area_id: null,
      province_options: [],
      city_options: [],
      area_options: [],
      disabledcity: true,
      disabledarea: true,
      type_id: '',
      oldObj:{},
      use_type: '',
      trade_type: '',
      invoice_type: '',
      delivery_type: '',
      payment_method: '',
      pick_method: '',
      br_reward_id_para:0,
      trade_term: '',
      checked: [],
      items: [],
      company_name: '',
      companyType: '',
      modalName: '',
      contractType: '',
      itemListShowFlag: false,
      readonly: 0,
      required: "required",
      requiredFor:'',
      requiredForShow:false,
      userDepartment: "purchase",
      br_pre_order:0,
      br_isuse_reward:0,
      settlementTimesArray: [{
        label: "1", value: 1
      }, {
        label: "2", value: 2
      }, {
        label: "3", value: 3
      }],
      settlement_times: '',
      purchaseOrderItem: {},
      bankName:'',
      accountNo:'',

      //yang
      purchaseUserSelected:[],
      together:'',
      rewardName:'',
      disabledBool:false,
      isExitInbound:false, //是否存在入库单
      //isSameBrandForItem:true,
      stockupTypeOptions:[{label:"流货",value:1},{label:"品牌",value:2}],
    }
  },
  watch:{
    //使用资金池
    br_isuse_reward:{
      handler(newval,oldval){
        if(newval==1){
          this.requiredFor='required'
          this.requiredForShow=true
        }
        if(newval==0&&this.br_pre_order==0){
          this.requiredFor=''
          //this.requiredForShow=true
          this.purchaseorder.br_reward_id=0
          this.rewardName=''
        }
      }
    },
    //预充
    br_pre_order:{
      handler(newval,oldval){
        if(newval==1){
          this.disabledBool=true
          this.requiredFor='required'
          this.requiredForShow=true
          this.br_isuse_reward = 1
        }
        if(newval==0){
          this.disabledBool=false
          this.br_isuse_reward = 0
          //this.requiredForShow=true
          this.purchaseorder.br_reward_id=0
          this.rewardName=''
        }
      }
    }
  },
  methods: {
    changeSelectPurchaser(key,event){
          this.together = ''
          let result = []
          event.forEach(item=>{
            result.push(item.value)
          })
         this.together = result.join(',')
         this.purchaseorder.together = this.together
    },
    onSelectWarehouse() {
      let returnData = {}
      if (this.$refs.whSelect.getSelected().length > 0) {
        returnData = this.$refs.whSelect.getSelected()[0]
        this.purchaseorder.warehouse_id = returnData.warehouse_id
        this.purchaseorder.warehouse_name = returnData.warehouse_name
        this.purchaseorder.end_place = returnData.location
        this.province_id = returnData.province_id
        if(returnData.province_id){
          this.onChangeProvince(returnData.province_id);
          this.city_id = returnData.city_id
        }
        if(returnData.city_id){
          this.onChangeCity(returnData.city_id);
          this.area_id = returnData.area_id
        }
        //this.purchaseorder.warehouse_area = returnData.province+returnData.city+returnData.area
        this.purchaseorder.warehouse_contact = returnData.contact
        this.purchaseorder.warehouse_mobile = returnData.mobile
        this.$forceUpdate()
      }
    },
    showFromWHModal(){
      this.$refs['fromWHModal'].show()
    },
    changeBrand(event){
      if(event){
        this.purchaseorder.bbm_brand_id= event.id;
      }else{
        this.purchaseorder.bbm_brand_id = '';
      }
    },
    changeSelect(key, event) {
      if (key === "type_id") {
        if (event.value === "3") {
          this.required = ""
          this.purchaseorder['invoice_type'] = 3
          this.invoice_type = getCodeLabel("invoice_type",3)
          //合同调集团接口开关 打开时
          if(this.oneSwitch == '1'){
            //清空合同信息
            this.purchaseorder['contract_no'] = undefined
            this.purchaseorder['contract_id'] = undefined
            this.purchaseorder['contract_type'] = undefined
            this.purchaseorder['payment_method'] = undefined
            this.purchaseorder['settlement_method'] = undefined
            this.purchaseorder['pick_method'] = undefined
            this.payment_method = ""
            this.pick_method = ""
            this.contractType = ""
          }
        } else {
          this.required = "required"
        }
      }
      if (key == 'settlement_times') {
        if (event == undefined) {
          this.purchaseorder.middle_pay_percent = 0
          this.purchaseorder.middle_pay_amount = 0
          this.purchaseorder.last_pay_percent = 0
          this.purchaseorder.last_pay_amount = 0
          this.purchaseorder.first_pay_percent = 0
          this.purchaseorder.first_pay_amount = 0
        } else {
          this.settlement_times = event.value
          if (event.value == 1) {
            this.purchaseorder.middle_pay_percent = 0
            this.purchaseorder.middle_pay_amount = 0
            this.purchaseorder.last_pay_percent = 0
            this.purchaseorder.last_pay_amount = 0
            this.purchaseorder.first_pay_percent = 100
            this.changePayPercent(100, 'first_pay_amount', 'first_pay_percent')
          }
          if (event.value == 2) {
            this.purchaseorder.middle_pay_percent = 0
            this.purchaseorder.middle_pay_amount = 0
            this.purchaseorder.last_pay_percent = 100 - this.purchaseorder.first_pay_percent
            this.changePayPercent(this.purchaseorder.last_pay_percent, 'last_pay_amount', 'last_pay_percent')
          }
        }
      }
      this.purchaseorder[key] = event == null ? undefined : event.value
      this.$forceUpdate()
    },
    showSingleModal(modalName) {
      if (modalName === '集团公司') {
        this.companyType = 'Group'
      } else if (modalName === '供应商'||modalName==='源头供应商') {
        this.companyType = 'Supplier'
      }
      this.modalName = modalName
      if (this.modalName === '供应商联系人') {
        if (this.purchaseorder.supplier_name == null) {
          this.$bvToast.toast('请先选择供应商~', {
            title: '异常',
            variant: 'danger',
            solid: true
          })
          return
        }
        axios
            .get('/api/contact/search', {
              params: {
                start: 1,
                limit: 50,
                order_by: 'contact_id',
                order_desc: true === true ? 'desc' : '',
                company_id: this.purchaseorder.supplier_id != undefined ? this.purchaseorder.supplier_id : 0
              }
            }).then(res => {
          if (res.data.data.list.length == 0) {
            this.$bvToast.toast('当前没有可选人员，请重新绑定~', {
              title: '异常',
              variant: 'danger',
              solid: true,
            })
            return
          }
          if (res.data.data.list.length == 1) {
            this.responsiveSet('supplier_contact_id', res.data.data.list[0].contact_id)
            this.responsiveSet('supplier_contact_name', res.data.data.list[0].name)
            this.responsiveSet('supplier_contact_mobile', res.data.data.list[0].mobile)
          } else {
            this.$refs['singleModal'].show()
          }
        })
      } else {
        this.$refs['singleModal'].show()
      }
    },
    responsiveSet(key, value) {
      this.$set(this.purchaseorder, key, value)
    },
    searchContract() {
      console.log("searchPurchase")
      console.log(this.oneSwitch)
      console.log(this.oneSwitch == '1')
      //合同调集团接口开关 打开时
      if(this.oneSwitch == '1'){
        console.log("one/contract/searchPurchase")
        //非vmi
        if(!(this.type_id&&this.type_id.value == '3')){
          let zt_company_id = this.purchaseorder.zt_company_id == undefined ? null : this.purchaseorder.zt_company_id
          let other_company_id = this.purchaseorder.one_supplier_id == undefined ? null : this.purchaseorder.one_supplier_id
          axios
              .post('api/contract/searchPurchase', {
                //itemid
                theirCorporationId: other_company_id,
                ourCorporationCode: zt_company_id,
                // type: 1,
                // status: "2,5"
              }).then(res => {
            let list = res.data.data.ext.list
            if (list&&list.length == 1) {
              this.responsiveSet('contract_no', list[0].contract_serial_number)//合同编号
              this.responsiveSet('contract_id', list[0].id)//合同id
              let contractType = list[0].contract_type;
              if(contractType&&contractType.indexOf('/') != -1){
                contractType = contractType.slice(contractType.lastIndexOf('/')+1);
              }
              this.responsiveSet('contract_type', getCodeLabel('agreement_specific_type_CG_one', contractType))//合同类型
              this.contractType = contractType
              let invoiceType = getCodeLabel('invoice_type_one', list[0].invoice_type)
              this.responsiveSet('invoice_type', invoiceType)//发票类型
              this.invoice_type = getCodeLabel('invoice_type', invoiceType)
              let moneyWay = getCodeLabel('agreement_pay_method_one', list[0].money_way)
              this.responsiveSet('payment_method', moneyWay)//付款方式
              this.payment_method = getCodeLabel('agreement_pay_method', moneyWay)
              this.responsiveSet('settlement_method', list[0].payment_comment)//结算方式
              //字典替换 TODO
              let goodsWay = getCodeLabel('pickup_method_CG_one', list[0].payment_type)
              this.responsiveSet('pick_method', goodsWay)//提货方式
              this.pick_method = getCodeLabel('pickup_method_CG', goodsWay)
              this.responsiveSet('one_contract_url', list[0].contract_detail_url)
              this.responsiveSet('one_contract_name', list[0].contract_title)
            } else {
              this.responsiveSet('contract_no', "")
              this.responsiveSet('contract_id', "")
              this.responsiveSet('contract_type', "")
              this.contractType = ""
              this.responsiveSet('invoice_type', undefined)//发票类型
              this.invoice_type = ""
              this.responsiveSet('payment_method', undefined)//付款方式
              this.payment_method = ""
              this.responsiveSet('settlement_method', "")   //结算方式
              this.responsiveSet('pick_method', undefined)//提货方式
              this.pick_method = ""
              this.responsiveSet('one_contract_url', undefined)
              this.responsiveSet('one_contract_name', undefined)
            }
          })
        }
      }else{
        let our_company_id = this.purchaseorder.company_id == undefined ? 0 : this.purchaseorder.company_id
        let other_company_id = this.purchaseorder.supplier_id == undefined ? 0 : this.purchaseorder.supplier_id
        axios
            .post('api/contract/select', {
              //itemid
              other_company_id: other_company_id,
              our_company_id: our_company_id,
              type: 1,
              status: "2,5"
            }).then(res => {
          let list = res.data.data.list
          if (list.length == 1) {
            this.responsiveSet('contract_no', list[0].agreement_no)
            this.responsiveSet('contract_id', list[0].contract_id)
            this.responsiveSet('contract_type', list[0].agreement_type)
            this.responsiveSet('settlement_method', list[0].balancemethod)
            this.contractType = getCodeLabel('agreement_specific_type_CG', list[0].agreement_type)
          } else {
            this.responsiveSet('contract_no', "")
            this.responsiveSet('contract_id', "")
            this.responsiveSet('contract_type', "")
            this.responsiveSet('settlement_method', "")   //结算方式
            this.contractType = getCodeLabel('agreement_specific_type_CG', "")
          }
        })
      }
    },

    setContact(){
      axios
          .get('/api/contact/search', {
            params: {
              start: 1,
              limit: 50,
              order_by: 'contact_id',
              order_desc: true === true ? 'desc' : '',
              company_id: this.purchaseorder.supplier_id != undefined ? this.purchaseorder.supplier_id : 0
            }
          }).then(res => {

        if (res.data.data.list.length == 0) {
          this.responsiveSet('supplier_contact_id', undefined)
          this.responsiveSet('supplier_contact_name', undefined)
          this.responsiveSet('supplier_contact_mobile', undefined)
        }else{
          this.responsiveSet('supplier_contact_id', res.data.data.list[0].contact_id)
          this.responsiveSet('supplier_contact_name', res.data.data.list[0].name)
          this.responsiveSet('supplier_contact_mobile', res.data.data.list[0].mobile)
        }
      })
    },
    onSelectSingle() {
      let data = {}
      // this.$refs.itemList.refetchData();
      switch (this.modalName) {
        case '集团公司': {
          data = this.$refs.companySelect.getSelected()[0]
          this.responsiveSet('company_id', data.company_id)
          this.responsiveSet('company_name', data.company_name)
          this.responsiveSet('zt_company_id', data.zt_company_id)
          this.searchContract()
          break
        }
        case '供应商': {
          data = this.$refs.companySelect.getSelected()[0]
          if(data.company_id==this.purchaseorder.supplier_id_source){
            this.$bvToast.toast('供应商与源头供应商重复', {
              title: '异常',
              variant: 'danger',
              solid: true
            })
            return
          }
          this.responsiveSet('supplier_id', data.company_id)
          this.responsiveSet('supplier_name', data.company_name)
          this.responsiveSet('one_supplier_id', data.one_supplier_id)
          this.searchContract()
          this.brandArr = [];
          if(this.brandAllArr){
            for (let i = 0; i < this.brandAllArr.length; i++) {
              if(this.brandAllArr[i].supplier_id === data.company_id){
                this.brandArr.push({"title":this.brandAllArr[i].brand_acount,"id":this.brandAllArr[i].brand_id})
              }
            }
          }
          //设置联系人
          if(this.id != 0||(this.purchaseorder.supplier_contact_id != undefined&&this.purchaseorder.supplier_contact_id != '')){
            this.setContact()
          }
          break
        }
        case '源头供应商': {
          data = this.$refs.companySelect.getSelected()[0]
          if(data.company_id==this.purchaseorder.supplier_id){
            this.$bvToast.toast('源头供应商与供应商重复', {
              title: '异常',
              variant: 'danger',
              solid: true
            })
            return
          }
          this.responsiveSet('supplier_id_source', data.company_id)
          this.responsiveSet('supplier_name_source', data.company_name)
          break
        }
        /* <!--yang-->*/
        case '收款账户': {

          data = this.$refs.ourBankSelects.getSelected()[0]
          this.responsiveSet('supplier_bank_id', data.account_id)
          this.bankName = data.bank_name
          this.accountNo = data.account_no
          this.searchContract()
          break
        }

        case '采购员': {
          data = this.$refs.purchaserSelect.getSelected()[0]
          this.responsiveSet('purchaser_id', data.user_id)
          this.responsiveSet('purchaser_name', data.full_name)
          this.responsiveSet('purchaser_mobile', data.mobile)
          break
        }
        case '供应商联系人': {
          data = this.$refs.contactSelect.getSelected()[0]
          this.responsiveSet('supplier_contact_id', data.contact_id)
          this.responsiveSet('supplier_contact_name', data.name)
          this.responsiveSet('supplier_contact_mobile', data.mobile)
          break
        }
        case '供应商合同': {
          data = this.$refs.contractSelect.getSelected()[0]
          //合同调集团接口开关 打开时
          if(this.oneSwitch == '1'){
            this.responsiveSet('contract_no', data.contract_serial_number)//合同编号
            this.responsiveSet('contract_id', data.id)//合同id
            let contractType = data.contract_type;
            if(contractType&&contractType.indexOf('/') != -1){
              contractType = contractType.slice(contractType.lastIndexOf('/')+1);
            }
            this.responsiveSet('contract_type', getCodeLabel('agreement_specific_type_CG_one', contractType))//合同类型
            this.contractType = contractType
            let invoiceType = getCodeLabel('invoice_type_one', data.invoice_type)
            this.responsiveSet('invoice_type', invoiceType)//发票类型
            this.invoice_type = getCodeLabel('invoice_type', invoiceType)
            let moneyWay = getCodeLabel('agreement_pay_method_one', data.money_way)
            this.responsiveSet('payment_method', moneyWay)//付款方式
            this.payment_method = getCodeLabel('agreement_pay_method', moneyWay)
            this.responsiveSet('settlement_method', data.payment_comment)//结算方式
            //字典替换 TODO
            let goodsWay = getCodeLabel('pickup_method_CG_one', data.payment_type)
            this.responsiveSet('pick_method', goodsWay)//提货方式
            this.pick_method = getCodeLabel('pickup_method_CG', goodsWay)
            this.responsiveSet('one_contract_url', data.contract_detail_url)
            this.responsiveSet('one_contract_name', data.contract_title)
          }else{
            this.responsiveSet('contract_no', data.agreement_no)
            this.responsiveSet('contract_id', data.contract_id)
            this.responsiveSet('contract_type', data.agreement_type)
            this.contractType = getCodeLabel('agreement_specific_type_CG', data.agreement_type)
          }
          break
        }
        case '申请人': {
          data = this.$refs.applierSelect.getSelected()[0]
          this.responsiveSet('applier_id', data.user_id)
          this.responsiveSet('applier_name', data.full_name)
          this.responsiveSet('applier_department_id', data.deptartment_id)
          this.responsiveSet('applier_department_name', getCodeLabel('department', data.deptartment_id))
          this.responsiveSet('applier_company_id', data.company_id)
          this.responsiveSet('applier_company_name', getCodeLabel('company', data.company_id))
          this.responsiveSet('applier_job_num', data.job_num)
          break
        }
        case '资金池': {
          data = this.$refs.brandRewardSelect.getSelected()[0]
          this.purchaseorder.br_reward_id=data.reward_id
          this.rewardName=data.brand_name +' '+ data.area_name
          this.br_reward_id_para=data.reward_id
          break
        }
        default: {
          //statements;
          break
        }
      }
    },
    dbOnSelectSingle(params) {
      // this.$refs.itemList.refetchData();
      switch (this.modalName) {
        case '集团公司': {
          this.responsiveSet('company_id', params.company_id)
          this.responsiveSet('company_name', params.company_name)
          this.responsiveSet('zt_company_id', params.zt_company_id)
          this.searchContract()
          break
        }
        case '供应商': {
          this.responsiveSet('supplier_id', params.company_id)
          this.responsiveSet('supplier_name', params.company_name)
          this.responsiveSet('one_supplier_id', params.one_supplier_id)
          this.searchContract()
          //设置联系人
          if(this.id != 0||(this.purchaseorder.supplier_contact_id != undefined&&this.purchaseorder.supplier_contact_id != '')){
            this.setContact()
          }
          break
        }
        case '源头供应商': {
          this.responsiveSet('supplier_id_source', params.company_id)
          this.responsiveSet('supplier_name_source', params.company_name)
          break
        }
        //yang
        // case '供应商': {
        //   // data = this.$refs.ourBankSelects.getSelected()[0]
        //   // this.responsiveSet('supplier_bank_id', data.account_id)
        //   // this.bankName = data.bank_name
        //   // this.accountNo = data.account_no
        //   console.log("fromChildren")
        //   console.log(params)
        //   this.responsiveSet('supplier_id', params.company_id)
        //   this.responsiveSet('supplier_name', params.company_name)
        //   break
        // }
        case '采购员': {
          this.responsiveSet('purchaser_id', params.user_id)
          this.responsiveSet('purchaser_name', params.full_name)
          this.responsiveSet('purchaser_mobile', params.mobile)
          break
        }
        case '供应商联系人': {
          this.responsiveSet('supplier_contact_id', params.contact_id)
          this.responsiveSet('supplier_contact_name', params.name)
          this.responsiveSet('supplier_contact_mobile', params.mobile)
          break
        }
        case '供应商合同': {
          //合同调集团接口开关 打开时
          if(this.oneSwitch == '1'){
            this.responsiveSet('contract_no', params.contract_serial_number)//合同编号
            this.responsiveSet('contract_id', params.id)//合同id
            let contractType = params.contract_type;
            if(contractType&&contractType.indexOf('/') != -1){
                contractType = contractType.slice(contractType.lastIndexOf('/')+1);
            }
            this.responsiveSet('contract_type', getCodeLabel('agreement_specific_type_CG_one', contractType))//合同类型
            this.contractType = contractType
            let invoiceType = getCodeLabel('invoice_type_one', params.invoice_type)
            this.responsiveSet('invoice_type', invoiceType)//发票类型
            this.invoice_type = getCodeLabel('invoice_type', invoiceType)
            let moneyWay = getCodeLabel('agreement_pay_method_one', params.money_way)
            this.responsiveSet('payment_method', moneyWay)//付款方式
            this.payment_method = getCodeLabel('agreement_pay_method', moneyWay)
            this.responsiveSet('settlement_method', params.payment_comment)//结算方式
            //字典替换 TODO
            let goodsWay = getCodeLabel('pickup_method_CG_one', params.payment_type)
            this.responsiveSet('pick_method', goodsWay)//提货方式
            this.pick_method = getCodeLabel('pickup_method_CG', goodsWay)
            this.responsiveSet('one_contract_url', params.contract_detail_url)
            this.responsiveSet('one_contract_name', params.contract_title)
          }else{
            this.responsiveSet('contract_no', params.agreement_no)
            this.responsiveSet('contract_id', params.contract_id)
            this.responsiveSet('contract_type', params.agreement_type)
            this.contractType = getCodeLabel('agreement_specific_type_CG', params.agreement_type)
            this.responsiveSet('settlement_method', params.balancemethod)
            this.responsiveSet('first_pay_percent', params.first_pay_percent)
            this.responsiveSet('first_pay_amount', params.first_pay_amount)
            this.responsiveSet('first_pay_condition', params.first_pay_condition)
            this.responsiveSet('first_pay_paydate', toDate(params.first_pay_paydate))
            this.responsiveSet('middle_pay_percent', params.middle_pay_percent)
            this.responsiveSet('middle_pay_amount', params.middle_pay_amount)
            this.responsiveSet('middle_pay_condition', params.middle_pay_condition)
            this.responsiveSet('middle_pay_paydate', toDate(params.middle_pay_paydate))
            this.responsiveSet('last_pay_percent', params.last_pay_percent)
            this.responsiveSet('last_pay_amount', params.last_pay_amount)
            this.responsiveSet('last_pay_condition', params.last_pay_condition)
            this.responsiveSet('last_pay_paydate', toDate(params.last_pay_paydate))
          }
          break
        }
        case '申请人': {
          this.responsiveSet('applier_id', params.user_id)
          this.responsiveSet('applier_name', params.full_name)
          this.responsiveSet('applier_department_id', params.deptartment_id)
          this.responsiveSet('applier_department_name', getCodeLabel('department', params.deptartment_id))
          this.responsiveSet('applier_company_id', params.company_id)
          this.responsiveSet('applier_company_name', getCodeLabel('company', params.company_id))
          this.responsiveSet('applier_job_num', params.job_num)
          break
        }
        default: {
          //statements;
          break
        }
      }
    },
    saveItem() {
      let items = this.$refs.itemList.dataList

      let res = []
      let subtotal = 0

      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        subtotal += (item.product_cost_tax*item.qty)
        let response = store.dispatch('purchaseorderitem/updateOrderItem', {
          //itemid
          itemid: item.item_id,
          //数量
          qty: item.qty,
          //单价
          productcost: parseFloat(item.product_cost),
          //含税单价
          productcosttax: parseFloat(item.product_cost_tax),
          //税率
          taxrate: item.tax_rate,
          isgift: item.is_gift,
          //预计毛利率
          expected_gross_profit_rate: item.expected_gross_profit_rate,
          sales_price:item.sales_price,
          sales_period:item.sales_period,
          gross_profit:this.$refs.itemList.gross_profit,
          purchaseTeamId:isEmpty(item.purchaseTeam)?null:item.purchaseTeam.value
        })
        res.push(response)
      }
      this.purchaseorder.purchase_total = subtotal.toFixed(2)
      return res
    },

    calculation(event, subtotal,params){
      store.dispatch('purchaseorder/changePayPercent', {
        paypercent: event,
        purchasetotal: subtotal
      }).then(res => {
        this.responsiveSet(params,res.data.data)
      })
    }

  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('contact')) store.registerModule('contact', contactStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('contact')) store.unregisterModule('contact')
    })

    // Register module
    if (!store.hasModule('bankaccount')) store.registerModule('bankaccount', bankaccountStore)
    if (!store.hasModule('purchaseorder')) store.registerModule('purchaseorder', purchaseorderStore)
    if (!store.hasModule('purchaseorderitem')) store.registerModule('purchaseorderitem', purchaseorderitemStore)
    if (!store.hasModule('contact')) store.registerModule('purchaseorderitem', contactStore)
    if (!store.hasModule('contract')) store.registerModule('contract', contractStore)
    if (!store.hasModule('user')) store.registerModule('user', userStore)
    if (!store.hasModule('brandreward')) store.registerModule('brandreward', brandrewardStore)
    if (!store.hasModule('area')) store.registerModule('area', areaStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('bankaccount')) store.unregisterModule('bankaccount')
      if (store.hasModule('purchaseorder')) store.unregisterModule('purchaseorder')
      if (store.hasModule('purchaseorderitem')) store.unregisterModule('purchaseorderitem')
      if (store.hasModule('contact')) store.unregisterModule('contact')
      if (store.hasModule('contract')) store.unregisterModule('contract')
      if (store.hasModule('user')) store.unregisterModule('user')
      if (store.hasModule('brandreward')) store.unregisterModule('brandreward')
      if (store.hasModule('area')) store.unregisterModule('area')
    })

    const getProvinceOptions = function () {
      store.dispatch('area/getProvince').then(res => {
        let area = res.data.data
        this.province_options = []
        for (let i = 0; i < area.length; i++) {
          this.province_options.push({label: area[i].area_name, value: area[i].area_id})
        }
      })
    }

    const onChangeProvince = function (param) {
      if (!isEmpty(param)) {
        this.disabledcity = false
        this.city_options = []
        this.getCityOptions(param)
      } else {
        this.disabledcity = true
      }
      this.city_id = null
      this.disabledarea = true
      this.area_id = null
      this.$forceUpdate()
    }

    const getCityOptions = function (parent_id) {
      store.dispatch('area/getCity', {parentId: parent_id}).then(res => {
        let area = res.data.data
        for (let i = 0; i < area.length; i++) {
          this.city_options.push({label: area[i].area_name, value: area[i].area_id})
        }
      })
    }

    const onChangeCity = function (param) {
      if (!isEmpty(param)) {
        this.disabledarea = false
        this.area_options = []
        this.getAreaOptions(param)
      } else {
        this.disabledarea = true
      }
      this.area_id = null
      this.$forceUpdate()
    }

    const getAreaOptions = function (parent_id) {
      store.dispatch('area/getCity', {parentId: parent_id}).then(res => {
        let area = res.data.data
        for (let i = 0; i < area.length; i++) {
          this.area_options.push({label: area[i].area_name, value: area[i].area_id})
        }
      })
    }

    const edit = function () {
      this.getProvinceOptions();

      store.dispatch('purchaseorder/edit',
          {
            id: this.id,
            readonly: this.readonly
          })
          .then(res => {
            if (res.data.code == 1) {
              toast.error(res.data.data)
              this.$router.push({name: 'apps-purchaseorder-list'})
            }
            this.brandAllArr = res.data.data.ext.brandList;
            if(this.brandAllArr&&res.data.data.supplier_id!== undefined && res.data.data.supplier_id!== ''){
              for (let i = 0; i < this.brandAllArr.length; i++) {
                if(this.brandAllArr[i].supplier_id === res.data.data.supplier_id){
                  this.brandArr.push({"title":this.brandAllArr[i].brand_acount,"id":this.brandAllArr[i].brand_id})
                }
                if(res.data.data.bbm_brand_id !== undefined && res.data.data.bbm_brand_id !== ''&& this.brandAllArr[i].brand_id === res.data.data.bbm_brand_id){
                  this.brand = {"title":this.brandAllArr[i].brand_acount,"id":this.brandAllArr[i].brand_id}
                }
              }
            }
            this.purchaseorder = res.data.data
            if(res.data.data.ext.company&&res.data.data.ext.company.zt_company_id){
              this.purchaseorder.zt_company_id = res.data.data.ext.company.zt_company_id
            }
            if(res.data.data.ext.supplier&&res.data.data.ext.supplier.one_supplier_id){
              this.purchaseorder.one_supplier_id = res.data.data.ext.supplier.one_supplier_id
            }
            this.isExitInbound = res.data.data.ext.isExitInbound;

            this.oldObj.type_id = this.purchaseorder.type_id
            this.oldObj.contract_no = this.purchaseorder.contract_no
            this.oldObj.contract_id = this.purchaseorder.contract_id
            this.oldObj.contract_type = this.purchaseorder.contract_type
            this.oldObj.payment_method = this.purchaseorder.payment_method
            this.oldObj.settlement_method = this.purchaseorder.settlement_method
            this.oldObj.pick_method = this.purchaseorder.pick_method
            this.oldObj.invoice_type = this.purchaseorder.invoice_type
            this.oldObj.one_contract_url = this.purchaseorder.one_contract_url
            this.oldObj.one_contract_name = this.purchaseorder.one_contract_name
            this.oldObj.company_id = this.purchaseorder.company_id
            this.oldObj.company_name = this.purchaseorder.company_name
            this.oldObj.zt_company_id = this.purchaseorder.zt_company_id
            this.oldObj.supplier_id = this.purchaseorder.supplier_id
            this.oldObj.supplier_name = this.purchaseorder.supplier_name
            this.oldObj.one_supplier_id = this.purchaseorder.one_supplier_id
            this.oldObj.supplier_contact_id = this.purchaseorder.supplier_contact_id
            this.oldObj.supplier_contact_name = this.purchaseorder.supplier_contact_name
            this.oldObj.supplier_contact_mobile = this.purchaseorder.supplier_contact_mobile
            //合同调集团接口开关 打开时
            if(this.oneSwitch == '1'&&this.purchaseorder.type_id){
              this.purchaseorder.type_id = this.purchaseorder.type_id.toString()
            }
            if(res.data.data.warehouse_area){
              let strings = res.data.data.warehouse_area.split(",");
              if(strings[0]){
                this.province_id = parseInt(strings[0])
                this.onChangeProvince(this.province_id);
              }
              if(strings[1]){
                this.city_id = parseInt(strings[1])
                this.onChangeCity(this.city_id);
              }
              if(strings[2]){
                this.area_id = parseInt(strings[2])
              }
            }
            if (this.purchaseorder.type_id == 3) {
              this.required = ""
            }
            this.trade_type = getCode('trade_type', this.purchaseorder.trade_type)
            this.trade_term = getCode('trade_term', this.purchaseorder.trade_term)
            this.itemListShowFlag = true
            if (this.id !== 0) {
              this.settlement_times = this.purchaseorder.settlement_times
              this.type_id = getCode('business_type', this.purchaseorder.type_id)
              this.use_type = getCode('purchase_use_type', this.purchaseorder.use_type)
              this.trade_type = getCode('trade_type', this.purchaseorder.trade_type)
              //合同调集团接口开关 打开时
              if(this.oneSwitch == '1'){
                this.invoice_type = getCodeLabel('invoice_type', this.purchaseorder.invoice_type)
                this.payment_method = getCodeLabel('agreement_pay_method', this.purchaseorder.payment_method)
                this.pick_method = getCodeLabel('pickup_method_CG', this.purchaseorder.pick_method)
              }else{
                this.invoice_type = getCode('invoice_type', this.purchaseorder.invoice_type)
                this.payment_method = getCode('agreement_pay_method', this.purchaseorder.payment_method)
                this.pick_method = getCode('pickup_method_CG', this.purchaseorder.pick_method)
              }
              this.delivery_type = getCode('delivery_method_purchase', this.purchaseorder.delivery_type)
              this.trade_term = getCode('trade_term', this.purchaseorder.trade_term)
              this.contractType = getCodeLabel('agreement_specific_type_CG', this.purchaseorder.contract_type)
              this.purchaseorder.first_pay_paydate = toDate(this.purchaseorder.first_pay_paydate)
              this.purchaseorder.middle_pay_paydate = toDate(this.purchaseorder.middle_pay_paydate)
              this.purchaseorder.last_pay_paydate = toDate(this.purchaseorder.last_pay_paydate)
              this.purchaseorder.delivery_time = toDate(this.purchaseorder.delivery_time)
              this.purchaseorder.arrival_time = toDate(this.purchaseorder.arrival_time)
              //yang
              if (!isEmpty(this.purchaseorder.br_reward_id)){
                 this.requiredFor='required'
                 this.requiredForShow=true
                 this.br_pre_order= isNumber(this.purchaseorder.br_pre_order)
                 this.br_isuse_reward= isNumber(this.purchaseorder.br_isuse_reward)
                 this.br_reward_id_para = this.purchaseorder.br_reward_id
                 store.dispatch('brandreward/searchById', {
                 id:this.purchaseorder.br_reward_id
              })
               .then(res => {
                 this.rewardName = res.data.data.brand_name+'/'+res.data.data.area_name;
               })
              }
              let together = this.purchaseorder.together
              let result = []
              if(together){
                result = together.split(",")
              }
              if(result.length>0){
                let allPurchaseUser = getCodeOptions('purchaseUser')
                result.forEach(item=>{
                   let value = item
                   allPurchaseUser.forEach(all=>{
                     let allValue = all.value
                      if(value===allValue){
                        this.purchaseUserSelected.push(all)
                      }
                   })
                })
              }
            }

            // yang 添加银行账户
            if(this.purchaseorder.supplier_bank_id!==undefined){
              store.dispatch('bankaccount/searchById', {
                id:this.purchaseorder.supplier_bank_id
              })
               .then(res => {
                        this.bankName = res.data.data==null?'':res.data.data.bank_name
                        this.accountNo =  res.data.data==null?'':res.data.data.account_no
                      })
            }
            //


          })
    }

    const view = function () {
      store.dispatch('purchaseorder/view', {id: this.id})
          .then(res => {
            this.purchaseorder = res.data.data
          })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const addExtraCostSubtotal = function () {
      store.dispatch('purchaseorderitem/calExtraCost', {
        orderid: this.purchaseorder.order_id,
        extracost: this.purchaseorder.extra_cost_subtotal,
      })
          .then(res => {
            toast.success('success!')
            this.$refs.itemList.refetchData()
          })

    }


    const save = async function () {
      this.purchaseorder.br_pre_order=isNumber(this.br_pre_order)
      this.purchaseorder.br_isuse_reward=isNumber(this.br_isuse_reward)
      this.purchaseorder.use_type=this.use_type

      if (this.purchaseorder.br_pre_order==1&&this.purchaseorder.br_isuse_reward==0) {
        toast.error("资金池预充必须选择使用资金池!")
        return false
      }


      if (this.purchaseorder.br_pre_order==1||this.purchaseorder.br_isuse_reward==1) {
        if(isEmpty(this.purchaseorder.br_reward_id)){
          toast.error("请选择资金池!")
          return false
        }
      }

      if(this.purchaseorder.bbm_brand_id !== undefined && this.purchaseorder.bbm_brand_id !== ''){
        if(this.purchaseorder.warehouse_id === undefined ||this.purchaseorder.warehouse_id === ''){
          toast.error("选择了品牌方 仓库必选!")
          return false
        }
      }
      if(this.province_id == undefined||this.province_id == ''||this.province_id == null||this.city_id == undefined || this.city_id ==''|| this.city_id ==null||this.area_id == undefined||this.area_id == ''||this.area_id == null){
        toast.error("省市区为必填项!")
        return false
      }
      this.purchaseorder.warehouse_area = this.province_id+","+this.city_id +","+this.area_id
      if(this.purchaseorder.end_place === undefined ||this.purchaseorder.end_place === ''){
        toast.error("详细地址为必填项!")
        return false
      }
      if(this.purchaseorder.warehouse_contact === undefined ||this.purchaseorder.warehouse_contact === ''){
        toast.error("收货人为必填项!")
        return false
      }
      if(this.purchaseorder.warehouse_mobile === undefined ||this.purchaseorder.warehouse_mobile === ''){
        toast.error("收货电话为必填项!")
        return false
      }

      //子表字段校验
      let validateItem = await this.$refs.itemList.$refs.observerItemList.validate()

      if (this.$refs.itemList.dataList.length === 0){
        toast.error("请添加产品")
        return
      }

      //yang
      let purchaseProductItem = this.$refs.itemList.dataList
      for(let a = 0 ; a<purchaseProductItem.length ; a ++ ){
        let product_cost_tax = purchaseProductItem[a].product_cost_tax
        let product_cost_tax_premodify  = isNumber(purchaseProductItem[a].product_cost_tax_premodify)
        let is_gift = purchaseProductItem[a].is_gift
        if(product_cost_tax_premodify>0){
           continue
        }else{
          if(product_cost_tax==0&&is_gift==0){
            toast.error("成本为0时，请勾选赠品！")
            return
          }
        }
      }


      //主页面字段校验
      let validate = await this.$refs.observer.validate()
      if (this.purchaseorder.purchase_total == undefined) {
        toast.error("预计采购费用不能为空!")
        return false
      }

      if (validateItem && validate) {
        //子页面保存
        let promises = this.saveItem()
        const purchaseOrder = {... this.purchaseorder}
        const res =  await axios.post('/api/purchaseorder/checkIdInbound', {'id': this.purchaseorder.order_id,})
        if (res.data.code === 0) {
          const isInbound = res.data.data.ext.isInbound
          if(isInbound == true){

            purchaseOrder.type_id = this.oldObj.type_id;
            purchaseOrder.contract_no = this.oldObj.contract_no;
            purchaseOrder.contract_id = this.oldObj.contract_id;
            purchaseOrder.contract_type = this.oldObj.contract_type;
            purchaseOrder.payment_method = this.oldObj.payment_method;
            purchaseOrder.settlement_method = this.oldObj.settlement_method;
            purchaseOrder.pick_method = this.oldObj.pick_method;
            purchaseOrder.invoice_type = this.oldObj.invoice_type;
            purchaseOrder.one_contract_url = this.oldObj.one_contract_url;
            purchaseOrder.one_contract_name = this.oldObj.one_contract_name;
            purchaseOrder.company_id = this.oldObj.company_id;
            purchaseOrder.company_name = this.oldObj.company_name;
            purchaseOrder.zt_company_id = this.oldObj.zt_company_id;
            purchaseOrder.supplier_id = this.oldObj.supplier_id;
            purchaseOrder.supplier_name = this.oldObj.supplier_name;
            purchaseOrder.one_supplier_id = this.oldObj.one_supplier_id;
            purchaseOrder.supplier_contact_id = this.oldObj.supplier_contact_id;
            purchaseOrder.supplier_contact_name = this.oldObj.supplier_contact_name;
            purchaseOrder.supplier_contact_mobile = this.oldObj.supplier_contact_mobile;
          }
        }
        purchaseOrder.first_pay_paydate = second(this.purchaseorder.first_pay_paydate)
        purchaseOrder.middle_pay_paydate = second(this.purchaseorder.middle_pay_paydate)
        purchaseOrder.last_pay_paydate = second(this.purchaseorder.last_pay_paydate)
        purchaseOrder.delivery_time = second(this.purchaseorder.delivery_time)
        purchaseOrder.arrival_time = second(this.purchaseorder.arrival_time)
        purchaseOrder['oa_reason'] = this.purchaseorder.ext['oa_reason']
        // this.purchaseorder.total_qty=null
        console.log(purchaseOrder)
        promises.push(store.dispatch('purchaseorder/save', purchaseOrder))
        let resList = await Promise.all(promises)
        if (resList.filter(item => item.data.code === 1).length === 0) {
          toast.success('数据已保存!')
          // store.dispatch('purchaseorder/saveAgain', this.purchaseorder)
          await this.$router.push({name: 'apps-purchaseorder-list'})
        } else {
          for (let i = 0; i < resList.length; i++) {
            let res = resList[i]
            let prefix = res.config.url.indexOf('purchaseorderitem') === -1 ? '表单数据' : `产品#${res.data.data.item_id}`
            if (res.data.code === 0) {
              toast.success(`${prefix}保存成功`)
            } else {
              toast.error(res.data.data)
            }
          }
          this.$refs.itemList.refetchData()
        }
      } else {
        toast.error('请正确填写字段')
      }
    }

    const onUploaded = function (id, attachment, result) {
      this.purchaseorder[id] = result
    }
    const fromChildren = function (val) {
      this.dbOnSelectSingle(val)
      this.$bvModal.hide('modal-single')
    }

    const changePayPercent = function (event, params, val) {
      if (parseInt(this.purchaseorder.first_pay_percent == undefined ? 0 : this.purchaseorder.first_pay_percent) + parseInt(this.purchaseorder.middle_pay_percent == undefined ? 0 : this.purchaseorder.middle_pay_percent) + parseInt(this.purchaseorder.last_pay_percent == undefined ? 0 : this.purchaseorder.last_pay_percent) > 100) {
        toast.error("超出最大百分比！")
        this.purchaseorder[val] = 0
        this.$forceUpdate()
        return false
      }

      let subtotal = 0
      let items = this.$refs.itemList.dataList
      for (let i = 0; i < items.length; i++) {
        subtotal += items[i].subtotal
      }
      this.purchaseorder.purchase_total = subtotal
      this.$forceUpdate()
      if (this.settlement_times == 2) {
        if (params == "first_pay_amount") {
          this.purchaseorder.last_pay_percent = 100 - parseInt(this.purchaseorder.first_pay_percent)
          store.dispatch('purchaseorder/changePayPercent', {
            paypercent: this.purchaseorder['last_pay_percent'],
            purchasetotal: subtotal
          }).then(res => {
            if (res.data.data == 0) {
              this.purchaseorder['last_pay_amount'] = (this.purchaseorder['last_pay_percent'] / 100 * subtotal).toFixed(2)
              res.data.data = this.purchaseorder['last_pay_amount']
            }
            this.purchaseorder['last_pay_amount'] = res.data.data
            this.$forceUpdate()
          })
        }
      }
      if (this.settlement_times == 3) {
        if (params == "middle_pay_amount") {
          this.purchaseorder['last_pay_percent'] = 100 - parseInt(this.purchaseorder.first_pay_percent) - parseInt(this.purchaseorder.middle_pay_percent)
          store.dispatch('purchaseorder/changePayPercent', {
            paypercent: this.purchaseorder['last_pay_percent'],
            purchasetotal: subtotal
          }).then(res => {
            if (res.data.data == 0) {
              this.purchaseorder['last_pay_amount'] = (this.purchaseorder['last_pay_percent'] / 100 * subtotal).toFixed(2)
              res.data.data = this.purchaseorder['last_pay_amount']
            }
            this.purchaseorder['last_pay_amount'] = res.data.data
            this.$forceUpdate()
          })
        }
      }

      store.dispatch('purchaseorder/changePayPercent', {
        paypercent: event,
        purchasetotal: subtotal
      }).then(res => {
        this.purchaseorder[params] = res.data.data
        this.$forceUpdate()
      })
    }

    const fromChildrenOnNum = function (val) {
      this.purchaseOrderItem = val
      let subtotal = 0
      for (let i = 0; i < val.length; i++) {
        subtotal += val[i].subtotal
      }
      // toast.success("明细修改，请注意财务信息内容修改并保存")
      this.purchaseorder.purchase_total = subtotal
      this.responsiveSet('purchase_total', subtotal)
      this.calculation(this.purchaseorder.first_pay_percent,subtotal,'first_pay_amount')
      this.calculation(this.purchaseorder.middle_pay_percent,subtotal,'middle_pay_amount')
      this.calculation(this.purchaseorder.last_pay_percent,subtotal,'last_pay_amount')
    }

    const disableTypeId = function (detailSize) {
      if(parseFloat(detailSize)>parseFloat('0')){
        this.disableTypeIdFlag = true
      }else{
        this.disableTypeIdFlag = false
      }
    }


    return {
      departmentId:computed(()=>store.getters['purchaseorder/getDepartmentId']),

      //page
      edit,
      view,
      cancel,
      save,
      addExtraCostSubtotal,
      isEmpty,

      //utils
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
      toDate,
      fromChildren,
      getProvinceOptions,
      onChangeProvince,
      onChangeCity,
      getCityOptions,
      getAreaOptions,
      changePayPercent,
      fromChildrenOnNum,
      disableTypeId,

    }
  },
  created() {
    this.oneSwitch = getCodeLabel("setting","one_switch")
    console.log("this.oneSwitch")
    console.log(this.oneSwitch)
    this.id = this.$route.query.id || 0
    this.readonly = this.$route.params.readonly || null
    if (this.$route.params.readonly==0||this.id==0){
      this.readonly=0
    }
    if(this.id==0){
      this.disableTypeIdFlag = false;
    }else{
      this.disableTypeIdFlag = true;
    }
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
