<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >


      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="3"
          >
            <b-alert
                variant="primary"
                show
            >
              <div class="alert-body">
                <span>预计综合毛利率：{{dataMeta.gross_profit}} %</span>
              </div>
            </b-alert>
          </b-col>

          <b-col
              cols="12"
              md="3"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="销售权:"
                label-for="user_level"
                label-size="sm"
            >
              <v-select
                  :options="getCodeOptions('purchase_team')"
                  v-model="purchaseTeam"
                  @input="changePurchaseTeam($event)"
                  name="user_level"
                  class="select-size-sm"
                  placeholder="请选择销售权团队"
                  :disabled="isExitInbound"
              />
            </b-form-group>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
              <b-button
                  variant="outline-primary"
                  @click="showProduct"
                  class="mr-1"
              >
                <span class="text-nowrap">添加产品</span>
              </b-button>

            <b-button
                variant="primary"
                @click="showOrClose"
                class="mr-1"
            >
              <span class="text-nowrap">商品导入</span>
            </b-button>

            <b-button
                    variant="outline-primary"
            >
              <span  class="text-nowrap" ><a href="https://oss.sbc.xyb2b.com/sbc/purchase_inbound/d8e12c4e89245ae21ca5cebebe315416.xlsx">导入模板</a></span>
            </b-button>

          </b-col>

        </b-row>

      </div>
      <ValidationObserver ref="observerItemList">
        <b-table
            noCollapse
            :tbody-tr-class="rowClass"
            ref="refListTable"
            class="position-relative"
            :items="searchList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
            v-model="dataList"
        >

          <!-- Columns -->
          <template #cell(name)="data">
            [#{{ data.item.product_id }}]{{ data.item.name }}
            <feather-icon
                icon="TwitchIcon"
                size="21"
                variant="success"
                :id="`item-row-${data.item.id}`"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '69码：'+data.item.ext.product.code+
              '<br>'+'箱规：'+data.item.ext.product.box_quantity+
              '</div>'"
            />

          </template>

          <template #cell(specification)="data">
            {{data.item.ext.product.specification}}
          </template>

          <template #cell(sales_price)="data">
            <div style="width: 70px">
              <ValidationProvider rules="required|positive" name="预计供价" #default="{ errors }"
                                  :vid="`sales_price_${data.index}`">
                <b-form-input
                    size="sm"
                    @change="qtyChange(errors.length,data.index)"
                    :state="errors.length > 0 ? false:null"
                    v-model="data.item.sales_price"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </template>

          <template #cell(sales_period)="data">
            <div style="width: 70px">
              <ValidationProvider rules="required|positiveInteger" name="销售周期" #default="{ errors }"
                                  :vid="`sales_period_${data.index}`">
                <b-form-input
                    size="sm"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    v-model="data.item.sales_period"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </template>


          <!-- Columns -->
          <template #cell(qty)="data">
            <div style="width: 70px">
              <ValidationProvider rules="required|nonnegativeInteger" name="数量" #default="{ errors }"
                                  :vid="`qty_${data.index}`">
                <b-form-input
                    size="sm"
                    @change="qtyChange(errors.length,data.index)"
                    :state="errors.length > 0 ? false:null"
                    v-model="data.item.qty"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </template>

          <!-- Columns -->
          <template #cell(product_cost)="data">
            <div style="width: 70px">

              <ValidationProvider :rules="`required${data.item.is_gift === 1 ? '' : '|nonnegative'}`" name="成本"
                                  #default="{ errors }" :vid="`product_cost_${data.index}`">
                <b-form-input
                    size="sm"
                    type="number"
                    @change="costOrCostTax(errors.length,'product_cost_tax',data.index)"
                    v-model="data.item.product_cost"
                    readonly
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </template>


          <!-- Columns -->
          <template #cell(product_cost_tax)="data">
            <div style="width: 70px">
              <ValidationProvider :rules="`required${data.item.is_gift === 1 ? '' : '|nonnegative'}`" name="含税成本"
                                  #default="{ errors }" :vid="`product_cost_tax_${data.index}`">
                <b-row>
                  <b-col md="10">
                    <b-form-input
                        size="sm"
                        type="number"
                        v-model="data.item.product_cost_tax"
                        @change="costOrCostTax(0,'product_cost',data.index)"
                        :state="errors.length > 0 ? false:null"
                        :readonly="data.item.is_gift === 1||data.item.ext.readonly==1"
                    />
                  </b-col>
                  <b-col md="2">
                    <feather-icon
                        v-if="data.item.ext.readonly==1"
                        icon="EditIcon"
                        class="mr-50"
                        @click="()=>{$refs['purchaseOrderChangeCostModal'].show(); purchaseOrderItem = data.item}"
                    />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </div>
          </template>

          <!-- Columns -->
          <template #cell(tax_rate)="data">
            <div style="width: 70px">

              {{getCodeLabel('tax_rate',data.value)}}
            </div>
          </template>

          <!-- Columns -->
          <template #cell(is_gift)="data">
            <div v-if="rewardId>0&&isNumber(data.item.product_cost_tax_premodify)>0">
              <b-form-checkbox
                      name="check-button"
                      switch
                      value="1"
                      unchecked-value="0"
                      disabled
                      @change="isGiftChange(data.item,data.value,data.index)"
                      v-model="data.item.is_gift"
              ></b-form-checkbox>
            </div>
            <div v-else>
              <b-form-checkbox
                      name="check-button"
                      switch
                      value="1"
                      unchecked-value="0"
                      @change="isGiftChange(data.item,data.value,data.index)"
                      v-model="data.item.is_gift"
              ></b-form-checkbox>
            </div>

          </template>

          <template #cell(purchase_team)="data">
            <ValidationProvider :rules="`required`" name="销售权"
                                #default="{ errors }" :vid="`product_cost_${data.index}`">
              <v-select
                  :options="getCodeOptions('purchase_team')"
                  v-model="data.item.purchaseTeam"
                  name="user_level"
                  class="select-size-sm"
                  placeholder="请选择销售权团队"
                  style="width: 200px"
                  :state="errors.length > 0 ? false:null"
                  disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>

          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
                <b-link @click="save(data.item,data.index)">
                  <feather-icon
                      icon="SaveIcon"
                      size="16"
                      class="align-middle text-body"
                  />
                </b-link>
                <b-link v-if="!data.item.ext.inbound" @click="del(data.item.item_id,data.index)">
                  <feather-icon
                      icon="XSquareIcon"
                      size="16"
                      class="align-middle text-body"
                  />
                </b-link>
          </template>

        </b-table>
      </ValidationObserver>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
          id="modal-select-product"
          ok-only
          ok-title="确认"
          @ok="onSelectProducts"
          cancel-title="取消"
          centered
          size="xl"
          title="选择商品"
          ref="productSelectModal"
      >
        <product-select
            ref="myProductSelect"
            :rewardId="rewardId"
            :selectState="1"
        >
        </product-select>
      </b-modal>
      <b-modal
          id="modal-purchase-product"
          centered
          size="xl"
          title="导入数据"
          ok-title="导入"
          ok-only
          @ok="exportExcel"
          ref="purchaseProductModal"
      >
        <b-row>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label-for="attachments_ids"
                label="相关附件："
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload :theme="'files'"
                                 :object_type="'excel'"
                                 @change="onUploadedExcel"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="false">
            <b-button
              variant="primary"
              @click="excelModelDownload"
               >
            <span class="text-nowrap">Excel模板下载</span>
            </b-button>
        </b-col>
        </b-row>
      </b-modal>
      <b-modal
          id="purchaseOrderChangeCostModal"
          ok-title="确认"
          cancel-title="取消"
          centered
          size="lg"
          @ok="addPurchaseOrderChangeCost"
          no-close-on-backdrop
          no-close-on-esc
          title="采购明细调整"
          ref="purchaseOrderChangeCostModal"
      >
        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="2"
              label="商品名称"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="1"
                size="sm"
                v-model="purchaseOrderItem.name"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="2"
              label="原成本"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="purchaseOrderItem.product_cost_tax"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="2"
              label="新成本"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                size="sm"
                type="number"
                v-model="purchaseOrderItem.new_sales_price"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="2"
              label="备注"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="purchaseOrderItem.memo"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="2"
              label="附件"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload :theme="'files'"
                               :object_type="'excel'"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>

      </b-modal>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  isNumber
} from '@core/utils/filter'
import purchaseorderitemUseList from './purchaseorderitemUseList'
import purchaseorderitemStore from './purchaseorderitemStore'
import productStore from '../product/productStore'
import ProductSelect from '@/views/apps/product/product-purchase/ProductSelect'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {useToast} from 'vue-toastification/composition'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import { add, divide, multiply, subtract } from '@core/utils/math/utils'
import purchaseorderchangecostStore from "@/views/apps/purchaseorderchangecost/purchaseorderchangecostStore";

export default {
  components: {
    BModal,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ProductSelect,
    AttachmentUpload
  },
  data() {
    return {
      selectedItem: {},
      dataList: [],
      costTaxReadonly:false,
      excelSqlId: '',
      gross_profit:0,
      purchaseOrderItem:{},
      purchaseTeam:{},
      purchaseTeamValue:0,
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') { return }

      if (item.ext.inbound > 0) {
        return 'table-warning'
      }
    },
    isGiftChange(item, value,index) {
      item.is_gift = parseInt(value)
      if (item.is_gift === 1){
        item.product_cost_tax = 0
        this.calTotal(index)
      }
    },
    validateRow : async function (index) {
      let refs = this.$refs.observerItemList.refs
      let validArr = []
      for (const key in refs) {
        if (refs.hasOwnProperty(key) && key.includes(index)) {
          let validate = await refs[key].validate()
          if (!validate['valid']) {
            validArr.push(key)
          }
        }
      }
      return validArr.length === 0
    },
    async validateAll() {
      let count = 0
      let items = this.dataList
      for (let i = 0; i < items.length; i++) {
        if (await this.$refs[`observer_qty_${i}`].validate() && await this.$refs[`observer_cost_${i}`].validate()) {
          count++
        }
      }
      return count === items.length
    },
    qtyChange(errors, index) {
      if (errors === 0) {
        this.calTotal(index)
      }
    },
    changeSelect(key, event, index) {
      this.dataList[index][key] = event == null ? undefined : event.value
      this.calTotal(index)
      // this.purchaseorder[key] = event == null ? undefined :  event.value
    },
    costOrCostTax(errors, flag, index) {
      if (errors === 0) {
        this.dataList[index][flag] = 0
        this.calTotal(index)
      }
    },


  },
  props: {
    purchaseOrder: {
      type: Object,
      default: {}
    },
    readonly:{
      type: Number,
      default: 0
    },
    rewardId: {
      type: Number,
      default: 0
    },
    isExitInbound:{
      type:Boolean,
      default:false
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseorderitem')) store.registerModule('purchaseorderitem', purchaseorderitemStore)
    if (!store.hasModule('product')) store.registerModule('product', productStore)
    if (!store.hasModule('purchaseorderchangecost')) store.registerModule('purchaseorderchangecost', purchaseorderchangecostStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseorderitem')) store.unregisterModule('purchaseorderitem')
      if (store.hasModule('product')) store.unregisterModule('product')
      if (store.hasModule('purchaseorderchangecost')) store.unregisterModule('purchaseorderchangecost')
    })

    const onSelectProducts = function () {
      let selected = this.$refs.myProductSelect.getSelected();
      addnewpro(selected,this)
    }

    const addnewpro = async function (items,_this) {

      for (let i = 0; i < items.length; i++) {
        let product_id = items[i]['product_id']
        let res = await store.dispatch('purchaseorderitem/addnewpro', {
          orderid: props.purchaseOrder.order_id,
          typeId:props.purchaseOrder.type_id,
          pid: product_id,
          purchaseTeamId:null
        })
        if (res.data.code === 0) {
          emit('disableTypeId',1)
          toast.success('数据已保存!')
        } else {
          toast.error('保存失败')
        }
      }
      refetchData()

    }

    const del = async function (item_id,index) {
      let res = confirm('确定删除吗？')
      if (res) {
        const res = await store.dispatch('purchaseorderitem/del', {
          id: item_id
        })
        if (res.data.code === 0) {
          toast.success(res.data.data)
          emit('disableTypeId',this.dataList.length-1)
          refetchData()
          /*const temp = this.dataList
          temp.splice(index,1)*/
          /*console.log(this.dataList)
          this.$emit("table", this.dataList)*/
        } else {
          toast.error(res.data.data)
        }
      }

    }

    const calTotal = function (index) {
      //验证
      let item = this.dataList[index]
      if (item.product_cost_tax==""){
        item.product_cost_tax = 0
      }
      store.dispatch('purchaseorderitem/caltotal', {
        //itemid
        itemid: item.item_id,
        //数量
        qty: item.qty,
        //单价
        productcost: parseFloat(item.product_cost),
        //含税成本
        productcosttax: parseFloat(item.product_cost_tax),
        //税率
        taxrate: item.tax_rate,
        items: this.dataList
      })
          .then(res => {
            if (res.data.code === 0){
              let data = res.data.data
              this.gross_profit = data.ext.grossProfit
              this.dataMeta.gross_profit = data.ext.grossProfit
              //加上product的数据
              data.ext = {
                product: item.ext.product,
                inbound:item.ext.inbound,
                readonly:item.ext.readonly
              }
              data.is_gift = item.is_gift
              data.sales_price = item.sales_price
              data.sales_period = item.sales_period
              data.purchaseTeam = item.purchaseTeam
              this.dataList.splice(index, 1, data)
              this.$emit("table",this.dataList)
            }else{
              item.qty = res.data.data.qty
              toast.error(res.data.data.msg)
            }

            // this.dataList[index]['product_cost'] = data['product_cost'];
            /*this.selectedItem['product_cost'] = data['product_cost']
            this.selectedItem['product_cost_subtotal'] = data['product_cost_subtotal']
            this.selectedItem['product_cost_tax'] = data['product_cost_tax']
            this.selectedItem['product_cost_tax_subtotal'] = data['product_cost_tax_subtotal']
            this.selectedItem['tax_subtotal'] = data['tax_subtotal']
            this.selectedItem['extra_cost'] = data['extra_cost']
            this.selectedItem['extra_cost_subtotal'] = data['extra_cost_subtotal']
            this.selectedItem['subtotal'] = data['subtotal']
            this.selectedItem['cost'] = data['cost']*/
          })
    }

    const showOrClose = function (){
      if(props.purchaseOrder.type_id == null || props.purchaseOrder.type_id =='' || props.purchaseOrder.type_id == undefined){
        alert("请先选择业务类型")
        return;
      }
      if(this.dataList.length>0){
        this.purchaseTeamValue =   this.dataList[0].purchase_team_id
      }else{
        this.purchaseTeamValue = this.purchaseTeam.value
      }
      if(isNumber(this.purchaseTeamValue)===0){
        toast.error("商品导入请选择销售权")
        return false
      }
      this.$refs.purchaseProductModal.show()
    }

    const showProduct = function (){
      if(props.purchaseOrder.type_id == null || props.purchaseOrder.type_id =='' || props.purchaseOrder.type_id == undefined){
        alert("请先选择业务类型")
        return;
      }
      this.$refs.productSelectModal.show()
    }

    const exportExcel = function () {
      const params = {
        excelSqlId: this.excelSqlId,
        orderId: props.purchaseOrder.order_id,
        typeId:props.purchaseOrder.type_id,
        purchaseTeamValue:this.purchaseTeamValue,
      }
      store.dispatch('purchaseorderitem/exportExcel', params)
          .then(response => {
            if (response.data.code == 0) {
              toast.success("导入成功")
              emit('disableTypeId',1)
              refetchData();
            } else {
              toast.error(response.data.data)
            }
          })
    }

    const onUploadedExcel = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const save = async function (item,index) {
      if(item.product_cost_tax==0&&item.is_gift==0){
         toast.error("成本为0时，请勾选赠品！")
          return
      }

      const success = await this.validateRow(index)
      if (!success){
        toast.error("请正确填写字段")
        return
      }
      store.dispatch('purchaseorderitem/updateOrderItem', {
        //itemid
        itemid: item.item_id,
        //数量
        qty: item.qty,
        //单价
        productcost: parseFloat(item.product_cost),
        //含税单价
        productcosttax: parseFloat(item.product_cost_tax),
        //税率
        taxrate: item.tax_rate,
        isgift: item.is_gift,
        expected_gross_profit_rate:item.expected_gross_profit_rate,
        sales_price:item.sales_price,
        sales_period:item.sales_period,
        gross_profit:this.gross_profit,
        purchaseTeamId:isEmpty(item.purchaseTeam)?null:item.purchaseTeam.value
      })
          .then(res => {
            if (res.data.code==0){
              toast.success('数据已保存!')
              refetchData()
            }else {
              toast.error(res.data.data)
            }

          })
    }

    const excelModelDownload = function (){
      store.dispatch('purchaseorderitem/excelModelDownload').then(res => {
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "采购单导入模板";

        /*-------------*/
        const link = document.createElement('a')

        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

      })
    }

    const addPurchaseOrderChangeCost = function () {
      // if(isNumber(this.purchaseOrderItem.product_cost_tax_modify)>0||isNumber(this.purchaseOrderItem.product_cost_tax_modifyed)>0){
      //   toast.error("请联系超管！")
      //   return;
      // }

      if (isEmpty(this.purchaseOrderItem.new_sales_price)){
        toast.error("请填写新供价!")
        return;
      }
      store.dispatch('purchaseorderchangecost/add',this.purchaseOrderItem)
          .then(res => {
            if (res.data.code==0){
              toast.success('成功!')
            }else {
              toast.error(res.data.data)
            }
          })
      this.purchaseOrderItem.length = {} ;
    }

    const onUploaded = function (id, attachment, result) {
      this.purchaseOrderItem.attachments = result
    }

    const changePurchaseTeam = function (event) {
      for (let i = 0; i < this.dataList.length; i++) {
        console.log(3,event)
        this.dataList[i].purchaseTeam = event
        this.dataList[i].purchase_team_id = event.value
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      gross_profit

      // UI
    } = purchaseorderitemUseList({
      order_id: props.purchaseOrder.order_id,
      readonly: props.readonly
    },emit)



    return {
      gross_profit,
      onSelectProducts,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      save,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      // page
      del,
      calTotal,
      exportExcel,
     // changeData,
      onUploadedExcel,
      showOrClose,
      showProduct,
      excelModelDownload,
      addPurchaseOrderChangeCost,
      onUploaded,
      changePurchaseTeam,
      isNumber
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
