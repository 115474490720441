/*
   * 加法
   * add(0.123 , 1.4567 , 10.56789)
   */
export function add(...val) {
  let sum = 0,
    maxDecimalLength = getMaxDecimalLength(...val)

  val.forEach((x, index) => {
    // 所有数值转为整数计算
    sum += getInteger(x,maxDecimalLength)
  })

  return sum / Math.pow(10, maxDecimalLength)
}

/*
* 减法
* subtract(0.123 , 1.4567 , 10.56789)
*/
export function subtract(...val) {
  let sum = 0,
    maxDecimalLength = getMaxDecimalLength(...val)

  val.forEach((x, index) => {
    // let nurVal = Math.round(x * Math.pow(10, decimalLength))
    let nurVal = getInteger(x,maxDecimalLength)

    if (index === 0) {
      sum = nurVal
    } else {
      sum -= nurVal
    }
  })

  return sum / Math.pow(10, maxDecimalLength)
}

/*
* 乘法
* multiply(0.123 , 1.4567 , 10.56789)
*/
export function multiply(...val) {
  let sum = 0,
    decimalLengthSum = 0

  val.forEach((x, index) => {
    // 获取当前小数位长度
    let decimalLength = getMaxDecimalLength(x)
    // 将当前数变为整数
    // let nurVal = Math.round(x * Math.pow(10, decimalLength))
    let nurVal = getInteger(x,decimalLength)

    decimalLengthSum += decimalLength

    if (index === 0) {
      sum = nurVal
    } else {
      sum *= nurVal
    }
  })

  return sum / Math.pow(10, decimalLengthSum)

}

/*
* 除法
* divide(0.123 , 1.4567 , 10.56789)
*/
export function divide(...val) {
  let sum = 0,
    decimalLengthSum = 0

  val.forEach((x, index) => {
    // 获取当前小数位长度
    let decimalLength = getMaxDecimalLength(x)
    // 将当前数变为整数
    // let nurVal = Math.round(x * Math.pow(10, decimalLength))
    let nurVal = getInteger(x,decimalLength)

    if (index === 0) {
      decimalLengthSum = decimalLength

      sum = nurVal
    } else {
      decimalLengthSum -= decimalLength
      if (nurVal === 0){
        sum = nurVal
      }else{
        sum /= nurVal
      }
    }
  })

  return sum / Math.pow(10, decimalLengthSum)
}

function getInteger(val,decimalLength){
  if (!val){
    return 0
  }
  return Math.round(val * Math.pow(10, decimalLength))
}

/*
* 获取小数位数
*/
function getMaxDecimalLength(...val) {
  // 最大小数位长度
  let maxDecimalLength = 0

  val.forEach((x) => {

    const strVal = x + '',
      dotIndex = strVal.indexOf('.')
    if (dotIndex > -1) {
      // 获取当前值小数位长度
      let curDecimalLength = strVal.length - 1 - dotIndex

      if (curDecimalLength > maxDecimalLength) {
        maxDecimalLength = curDecimalLength
      }
    }
  })

  return maxDecimalLength

}
