<template>
  <div>

      <b-form-group
          label-cols="3"
          label-cols-lg="3"
          :label="label"
          label-size="sm"
          class="mb-1"
          v-if="showLabel"
      >
        <b-form-input
            size="sm"
            v-if="type==='input'"
            class="d-inline-block"
            @click="showSingleModal(modalName)"
            :value="value"
            :placeholder="placeholder"
            readonly
        />
        <flat-pickr
            v-if="type==='data'"
            class="form-control"
            @input="emit($event)"
            :value="value"
        />
        <flat-pickr
                v-if="type==='dataAndTime'"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                @input="emit($event)"
                :value="value"
        />
        <flat-pickr
                v-if="type==='time'"
                class="form-control"
                :config="{ mode: 'range'}"
                @input="emit($event)"
                :value="value"
                readonly
        />
      </b-form-group>

    <!--不显示label的情况下-->
    <b-form-input
        size="sm"
        class="d-inline-block"
        @click="showSingleModal(modalName)"
        :value="value"
        :placeholder="placeholder"
        readonly
        v-else
    />
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <company-select
          ref="companySelect" :is-all-state="isAllState"  :showAllLogistics="showAllLogistics" :warehouseId="warehouseId" :warehouse-id-from="warehouseIdFrom" :warehouse-id-to="warehouseIdTo" :companyType="companyType" :storeId="storeId" :setting_ban_type="setting_ban_type" v-if="modalName === '销售主体' || modalName ==='渠道方'|| modalName ==='供应商'|| modalName ==='终端客户'||modalName === '客户名称' ||modalName==='仓库方' || modalName==='装卸队' || modalName==='承运商' || modalName==='签约主体'"
      >
      </company-select>

      <product-select
          ref="productSelect"  v-else-if="modalName === '商品' "
      >
      </product-select>

      <!--   yang   -->

      <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
      <user-select
          ref="userSelect" :is-all-state="isAllState" :userDepartment="userDepartment" v-else-if="modalName === '销售员' || modalName ==='创建人' || modalName==='责任人' "
      >
      </user-select>

      <store-list
          ref="storeList" v-else-if=" modalName === '店铺' ||modalName === '团队' " :company_id="company_id" :cs_company_id="cs_company_id" :store_ids="store_ids"
      >
      </store-list>

      <store-warehouse-list
              ref="storeWarehouseList" v-else-if="modalName === '仓库店铺' "
      >
      </store-warehouse-list>

      <warehouse-list
          ref="warehouseSelect" v-else-if="modalName === '仓库'"
      >
      </warehouse-list>

      <brand-select
          ref="brandSelect" v-else-if="modalName ==='品牌名称'">
      </brand-select>

      <brand-reward-select
          ref="brandRewardSelect"  v-else-if="modalName ==='资金池'">
      </brand-reward-select>
    </b-modal>

  </div>

</template>

<script>

import XyGroup from '@/views/components/xy/XyGroup'
import flatPickr from 'vue-flatpickr-component'
import {BInputGroup, BFormInput, BInputGroupAppend, BInputGroupPrepend, BRow, BCol, BButton,VBModal} from 'bootstrap-vue'
import {onMounted, reactive, toRefs} from "@vue/composition-api";
import store from "@/store";
import vSelect from 'vue-select'
import CompanySelect from "@/views/apps/company/CompanySelect"

import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import StoreList from "@/views/apps/store/store-modal/StoreListSelect";
import StoreWarehouseList from "@/views/apps/store/store-warehouse/StoreList";
import WarehouseList from "@/views/apps/warehouse/warehouse-modal/WarehouseList";
import ProductSelect from '@/views/apps/product/product-salesorder/ProductSelect'
import modal from "@/views/components/modal/Modal";
import BrandSelect from "@/views/apps/brand/BrandSelect";
import BrandRewardSelect from "@/views/apps/brandreward/BrandRewardSelect";

export default {
  components: {
    XyGroup,
    flatPickr,
    BInputGroup,
    VBModal,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    vSelect,
    CompanySelect,
    UserSelect,
    StoreList,
    WarehouseList,
    ProductSelect,
    StoreWarehouseList,
    BrandSelect,
    BrandRewardSelect,
  },
  data(){
    return{
      data:{},
      companyType: '',
    }
  },
  props: {
    //前面的显示文字
    label:{
      type: String,
      default: ''
    },
    //类型:input:普通输入框,time:日期选择框
    type: {
      type: String,
      default: 'input'
    },
    //显示的值
    value: {
      type:String,
      default:''
    },
    //弹窗的名字
    modalName:{
      type:String,
      default:''
    },
    //(数组)需要赋值的值(第一个值是名称,第二个值是id)
    params:{

    },
    //输入框提示字符
    placeholder:{
      type:String,
      default:''
    },
    //是否只读
    isReadonly:{
      type:Boolean,
      default:false
    },
    warehouseId:{
      type:Number,
      default:0
    },
    warehouseIdFrom:{
      type:Number,
      default:0
    },
    warehouseIdTo:{
      type:Number,
      default:0
    },
    showAllLogistics:{
      type:Boolean,
      default:true
    },
    //是否显示label
    showLabel:{
      type:Boolean,
      default:true
    },
    userDepartment:{
      type:String,
      default:''
    },
    //是否显示所有
    isAllState:{
      type:Boolean,
      default: false
    },
    company_id:{
      type:Number,
      default:null
    },
    //company_store中间表的company_id
    cs_company_id:{
      type:Number,
      default:null
    },
    store_ids:{
      type:String,
      default:""
    },
    storeId:{
      type:Number,
      default:0
    },
    setting_ban_type:{
      type:Number,
      default:0
    },
  },
  methods:{
    emit:function(event){
      for (let i = 0; i < this.params.length; i++) {
        this.data[this.params[i]] = event
      }
      this.$emit('change',this.data)
    },

    showSingleModal(modalName) {
      if (modalName === '渠道方') {
        this.companyType = 'ChannelCustomer'
      } else if (modalName === '销售主体'|| modalName ==='主体' || modalName === '签约主体') {
        this.companyType = 'Group'
      }
      else if (modalName === '供应商') {
        this.companyType = 'Supplier'
      }
      else if (modalName === '终端客户') {
        this.companyType = 'OffSalesChannelCustomer'
      }
      else if (modalName === '客户名称') {
        this.companyType = 'SalesCustomer'
      }
      else if (modalName === '仓库方') {
        //this.companyType = 'ChannelCustomer'
        this.companyType = 'WareHouse'
      }
      else if (modalName === '装卸队') {
        this.companyType = 'LoadTeam'
      }
      else if (modalName === '承运商') {
        this.companyType = 'Logistics'
      }
      else if (modalName === '签约主体') {
        this.companyType = 'GroupAndOutside'
      }
      if(!this.isReadonly){
        //不是只读才显示模态框
        this.$refs['singleModal'].show()
      }
    },
    onSelectSingle() {
      let data = {}
      switch (this.modalName) {
        case '渠道方': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '客户名称': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '终端客户': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '销售主体': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '供应商': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '销售员': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].full_name;
            this.data[this.params[1]] = data[0].user_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '创建人': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].full_name;
            this.data[this.params[1]] = data[0].user_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '责任人': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].full_name;
            this.data[this.params[1]] = data[0].user_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '店铺': {
          data = this.$refs.storeList.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].store_name;
            this.data[this.params[1]] = data[0].store_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '团队': {
          data = this.$refs.storeList.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].store_name;
            this.data[this.params[1]] = data[0].store_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '仓库店铺': {
          data = this.$refs.storeWarehouseList.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].store_name;
            this.data[this.params[1]] = data[0].store_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '仓库': {
          data = this.$refs.warehouseSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].warehouse_name;
            this.data[this.params[1]] = data[0].warehouse_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '仓库方': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '装卸队': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '承运商': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '商品': {
          data = this.$refs.productSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].name;
            this.data[this.params[1]] = data[0].product_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '品牌名称': {
          data = this.$refs.brandSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].name;
            this.data[this.params[1]] = data[0].brand_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '资金池':{
          data = this.$refs.brandRewardSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].brand_name +' '+ data[0].area_name
            this.data[this.params[1]] = data[0].reward_id
            this.data[this.params[2]] = data[0].recharge_remain == null ? 0:data[0].recharge_remain
            this.data[this.params[3]] = data[0].return_remain == null ? 0:data[0].return_remain
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '签约主体': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        default: {
          break
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
