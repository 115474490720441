<template>
  <b-form-group
      :label-cols="cols"
      :label-cols-lg="cols"
      label-size="sm"
      v-bind="$attrs"
  >
    <slot></slot>
  </b-form-group>
</template>

<script>
export default {
  props: {},
  computed: {
    cols() {
      return this.$attrs['label-cols'] || 3
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
