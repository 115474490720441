import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    condition:{
      supplier_name : '',
      brand_name : '',
      area_name : '',
      status : {},
    },
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/brandrewardorder/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/brandrewardorder/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/brandrewardorder/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/brandrewardorder/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/brandrewardorder/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    audit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/brandrewardorder/audit', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportToExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios({
              url: '/api/exportexcelfile/brandrewardorderExport',
              method:'get',
              params: params,
              responseType: 'blob',
            }
        )
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
